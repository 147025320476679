import { applyMiddleware, compose, createStore, combineReducers } from "redux";
import thunk from "redux-thunk";
import blockchainReducer from "./blockchain/blockchainReducer";
import companionsReducer from "./companions/companionsReducer";
import rewardsReducer from "./rewards/rewardsReducer";
import fawniesReducer from "./fawnies/fawniesReducer";
import dustReducer from "./dust/dustReducer";
import storiesReducer from "./stories/storiesReducer";

const rootReducer = combineReducers({
  blockchain: blockchainReducer,
  rewards: rewardsReducer,
  companions: companionsReducer,
  fawnies: fawniesReducer,
  dust: dustReducer,
  stories: storiesReducer,
});

const middleware = [thunk];
const composeEnhancers = compose(applyMiddleware(...middleware));

const configureStore = () => {
  return createStore(rootReducer, composeEnhancers);
};

const store = configureStore();

export default store;
