const initialState = {
  loading: false,
  merkleRoot: "",
  calculatedRoot: "",
  merkleProof: [],
  claimed: 0,
  maxClaim: 0,
  publicMintOpen: false,
  publicMintPrice: 4,
  holderReserve: 0,
  totalSupply: 0,
  error: false,
  errorMsg: "",
};

const fawniesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHECK_FAWNIES_REQUEST":
      return {
        ...state,
        loading: true,
        error: false,
        errorMsg: "",
      };
    case "CHECK_FAWNIES_SUCCESS":
      return {
        ...state,
        loading: false,
        merkleRoot: action.payload.merkleRoot,
        calculatedRoot: action.payload.calculatedRoot,
        merkleProof: action.payload.merkleProof,
        claimed: action.payload.claimed,
        maxClaim: action.payload.maxClaim,
        publicMintOpen: action.payload.publicMintOpen,
        publicMintPrice: action.payload.publicMintPrice,
        holderReserve: action.payload.holderReserve,
        totalSupply: action.payload.totalSupply,
        error: false,
        errorMsg: "",
      };
    case "CHECK_FAWNIES_FAILED":
      return {
        ...initialState,
        loading: false,
        error: true,
        errorMsg: action.payload,
      };
    default:
      return state;
  }
};

export default fawniesReducer;
