// log
import store from "../store";
import Web3 from "web3";
const { MerkleTree } = require('merkletreejs')


const fetchStoriesRequest = () => {
  return {
    type: "CHECK_STORIES_REQUEST",
  };
};

const fetchStoriesSuccess = (payload) => {
  return {
    type: "CHECK_STORIES_SUCCESS",
    payload: payload,
  };
};

const fetchStoriesFailed = (payload) => {
  return {
    type: "CHECK_STORIES_FAILED",
    payload: payload,
  };
};

export const fetchStories = (blockchainAccount = "") => {
  if(blockchainAccount === null) { blockchainAccount = ""; }
  return async (dispatch) => {
    dispatch(fetchStoriesRequest());
    try {
      let activeRecipes = await store
        .getState()
        .blockchain.storiesMinterContract.methods.getActiveRecipes()
        .call();
      let tokenBalances = [0,0,0,0,0,0,0,0];
      if(blockchainAccount != "") {
        tokenBalances = await store
        .getState()
        .blockchain.storiesContract.methods.balanceOfBatch(Array(8).fill(blockchainAccount), [0,1,2,3,4,5,6,7])
        .call();
      }

      dispatch(
        fetchStoriesSuccess({
          activeRecipes,
          tokenBalances,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchStoriesFailed("Could not load data from contract."));
    }
  };
};
