// log
import store from "../store";
import Web3 from "web3";
const { MerkleTree } = require('merkletreejs')


const fetchFawniesRequest = () => {
  return {
    type: "CHECK_FAWNIES_REQUEST",
  };
};

const fetchFawniesSuccess = (payload) => {
  return {
    type: "CHECK_FAWNIES_SUCCESS",
    payload: payload,
  };
};

const fetchFawniesFailed = (payload) => {
  return {
    type: "CHECK_FAWNIES_FAILED",
    payload: payload,
  };
};

export const fetchFawnies = (blockchainAccount) => {
  return async (dispatch) => {
    dispatch(fetchFawniesRequest());
    try {
      const merkleLeavesResponse = await fetch("/config/fawnies_merkletree.json", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      var options = {
        hashLeaves: false,
        sortLeaves: true,
        sortPairs: true,
        duplicateOdd: false,
        isBitcoinTree: false
      }
      const merkleLeaves = await merkleLeavesResponse.json();

      const hashedLeaves = merkleLeaves.map(x => Web3.utils.keccak256(
        Web3.utils.encodePacked(
            {value: Web3.utils.toChecksumAddress(x["address"]), type: 'address'},
            {value: x["maxClaim"], type: 'uint256'}
          )
      ));
      let maxClaim = 0;
      for(let i = 0;i < merkleLeaves.length;i++) {
        if(Web3.utils.toChecksumAddress(merkleLeaves[i]["address"]) == Web3.utils.toChecksumAddress(blockchainAccount)) {
          maxClaim = merkleLeaves[i]["maxClaim"]
        }
      }
      const tree = new MerkleTree(hashedLeaves, Web3.utils.keccak256, options);
      const calculatedRoot = tree.getRoot().toString('hex');
      const currentLeaf = Web3.utils.keccak256(Web3.utils.encodePacked(
            {value: Web3.utils.toChecksumAddress(blockchainAccount), type: 'address'},
            {value: maxClaim, type: 'uint256'}
          ));
      const currentProof = tree.getHexProof(currentLeaf.toString('hex'));
      const merkleProof = currentProof.map(x => x.toString('hex'));
      let claimed = await store
        .getState()
        .blockchain.fawniesContract.methods._claimed(blockchainAccount)
        .call();
      let publicMintOpen = await store
        .getState()
        .blockchain.fawniesContract.methods._publicMintOpen()
        .call();
      let publicMintPrice = await store
        .getState()
        .blockchain.fawniesContract.methods._mintPrice()
        .call();
      let merkleRoot = await store
        .getState()
        .blockchain.fawniesContract.methods._merkleRoot()
        .call();
      let holderReserve = await store
        .getState()
        .blockchain.fawniesContract.methods.holderReserve()
        .call();
      let totalSupply = await store
        .getState()
        .blockchain.fawniesContract.methods.totalSupply()
        .call();
      console.log(merkleLeaves);
      console.log("Calculated root: " + calculatedRoot);
      console.log("Current root: " + merkleRoot.toString('hex'));
      console.log("Current leaf: " + currentLeaf);
      console.log(merkleProof);
      console.log("Max claim: " + maxClaim);
      console.log((merkleRoot.toString('hex')) == ("0x" + calculatedRoot.toString('hex')));


      dispatch(
        fetchFawniesSuccess({
          merkleRoot,
          calculatedRoot,
          merkleProof,
          claimed,
          maxClaim,
          publicMintOpen,
          publicMintPrice,
          holderReserve,
          totalSupply
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchFawniesFailed("Could not load data from contract."));
    }
  };
};
