const initialState = {
  loading: false,
  balance: 0,
  unclaimedStakingDust: 0,
  unclaimedPixies: [],
  pixieIsUnclaimed: false,
  error: false,
  errorMsg: "",
};

const companionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHECK_DUST_REQUEST":
      return {
        ...state,
        loading: true,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DUST_SUCCESS":
      return {
        ...state,
        loading: false,
        balance: action.payload.balance,
        unclaimedStakingDust: action.payload.unclaimedStakingDust,
        unclaimedPixies: action.payload.unclaimedPixies,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DUST_BALANCE_SUCCESS":
      return {
        ...state,
        loading: false,
        balance: action.payload.balance,
        error: false,
        errorMsg: "",
      };
    case "CHECK_PIXIE_CLAIMED_SUCCESS":
      return {
        ...state,
        loading: false,
        pixieIsClaimed: action.payload.pixieIsClaimed,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DUST_FAILED":
      return {
        ...initialState,
        loading: false,
        error: true,
        errorMsg: action.payload,
      };
    default:
      return state;
  }
};

export default companionsReducer;
