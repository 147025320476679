const initialState = {
  loading: false,
  pixieJars: [],
  companionJars: [],
  pandies: [],
  fawnies: [],
  pixiesApproved: false,
  pandiesApproved: false,
  fawniesApproved: false,
  error: false,
  errorMsg: "",
};

const companionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHECK_COMPANIONS_REQUEST":
      return {
        ...state,
        loading: true,
        error: false,
        errorMsg: "",
      };
    case "CHECK_COMPANIONS_SUCCESS":
      return {
        ...state,
        loading: false,
        pixieJars: action.payload.pixieJars,
        companionJars: action.payload.companionJars,
        pandies: action.payload.pandies,
        fawnies: action.payload.fawnies,
        pixiesApproved: action.payload.pixiesApproved,
        pandiesApproved: action.payload.pandiesApproved,
        fawniesApproved: action.payload.fawniesApproved,
        error: false,
        errorMsg: "",
      };
    case "CHECK_CHECK_APPROVALS_SUCCESS":
      return {
        ...state,
        loading: false,
        pixiesApproved: action.payload.pixiesApproved,
        pandiesApproved: action.payload.pandiesApproved,
        fawniesApproved: action.payload.fawniesApproved,
        error: false,
        errorMsg: "",
      };
    case "CHECK_COMPANIONS_FAILED":
      return {
        ...initialState,
        loading: false,
        error: true,
        errorMsg: action.payload,
      };
    default:
      return state;
  }
};

export default companionsReducer;
