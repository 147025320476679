const initialState = {
  loading: false,
  account: "",
  rewardsContract: null,
  pixiejarsContract: null,
  pandiesContract: null,
  fawniesContract: null,
  walletOfOwnerContract: null,
  companionjarsContract: null,
  pixiedustContract: null,
  storiesContract: null,
  storiesMinterContract: null,
  web3: null,
  errorMsg: "",
};

const blockchainReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CONNECTION_REQUEST":
      return {
        ...initialState,
        loading: true,
      };
    case "CONNECTION_SUCCESS":
      return {
        ...state,
        loading: false,
        account: action.payload.account,
        rewardsContract: action.payload.rewardsContract,
        pixiejarsContract: action.payload.pixiejarsContract,
        companionjarsContract: action.payload.companionjarsContract,
        pandiesContract: action.payload.pandiesContract,
        fawniesContract: action.payload.fawniesContract,
        pixiedustContract: action.payload.pixiedustContract,
        walletOfOwnerContract: action.payload.walletOfOwnerContract,
        storiesContract: action.payload.storiesContract,
        storiesMinterContract: action.payload.storiesMinterContract,
        web3: action.payload.web3,
      };
    case "LIGHT_CONNECTION_SUCCESS":
      return {
        ...state,
        loading: false,
        rewardsContract: action.payload.rewardsContract,
        pixiejarsContract: action.payload.pixiejarsContract,
        companionjarsContract: action.payload.companionjarsContract,
        pandiesContract: action.payload.pandiesContract,
        fawniesContract: action.payload.fawniesContract,
        pixiedustContract: action.payload.pixiedustContract,
        walletOfOwnerContract: action.payload.walletOfOwnerContract,
        storiesContract: action.payload.storiesContract,
        storiesMinterContract: action.payload.storiesMinterContract,
        web3: action.payload.web3,
      };
    case "CONNECTION_FAILED":
      return {
        ...initialState,
        loading: false,
        errorMsg: action.payload,
      };
    case "UPDATE_ACCOUNT":
      return {
        ...state,
        account: action.payload.account,
      };
    default:
      return state;
  }
};

export default blockchainReducer;
