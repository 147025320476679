// constants
import WalletConnectProvider from "@walletconnect/web3-provider";
import WalletLink from "walletlink";
import Web3EthContract from "web3-eth-contract";
import Web3 from "web3";
import Web3Modal from "web3modal";

// log
import { fetchRewards } from "../rewards/rewardsActions";
import { fetchStories } from "../stories/storiesActions";

const RPC_URL = "https://eth-mainnet.g.alchemy.com/v2/IoGB36GrH1HJcjoj5I8y0-kR1NgzJg-0";

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider, // required
    options: {
      rpc: {
        1: RPC_URL,
      },
      timeout: 30000
    }
  }, 
  walletlink: {
    package: WalletLink, // Required
    options: {
      appName: "Pixie Jars NFT", // Required
      rpc: RPC_URL, // Optional if `infuraId` is provided; otherwise it's required
      chainId: 1, // Optional. It defaults to 1 if not provided
      appLogoUrl: null, // Optional. Application logo image URL. favicon is used if unspecified
      darkMode: false, // Optional. Use dark theme, defaults to false
      timeout: 30000
    }
  }
};

const web3Modal = new Web3Modal({
  network: "mainnet", // optional
  providerOptions,
  theme: "dark"
});

const connectRequest = () => {
  return {
    type: "CONNECTION_REQUEST",
  };
};

const connectSuccess = (payload) => {
  return {
    type: "CONNECTION_SUCCESS",
    payload: payload,
  };
};

const lightConnectSuccess = (payload) => {
  return {
    type: "LIGHT_CONNECTION_SUCCESS",
    payload: payload,
  };
};

const connectFailed = (payload) => {
  return {
    type: "CONNECTION_FAILED",
    payload: payload,
  };
};

const updateAccountRequest = (payload) => {
  return {
    type: "UPDATE_ACCOUNT",
    payload: payload,
  };
};

export const lightConnect = () => {
  return async (dispatch) => {
    dispatch(connectRequest());
    const rewards_abiResponse = await fetch("/config/rewards_abi.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const pixiejars_abiResponse = await fetch("/config/pixie_jars_abi.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const fawnies_abiResponse = await fetch("/config/fawnies_abi.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const walletofowner_abiResponse = await fetch("/config/walletofowner_abi.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const companionjars_abiResponse = await fetch("/config/companion_jars_abi.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const pixiedust_abiResponse = await fetch("/config/pixie_dust_abi.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const stories_abiResponse = await fetch("/config/stories_abi.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const storiesminter_abiResponse = await fetch("/config/stories_minter_abi.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const rewards_abi = await rewards_abiResponse.json();
    const pixiejars_abi = await pixiejars_abiResponse.json();
    const fawnies_abi = await fawnies_abiResponse.json();
    const walletofowner_abi = await walletofowner_abiResponse.json();
    const companionjars_abi = await companionjars_abiResponse.json();
    const pixiedust_abi = await pixiedust_abiResponse.json();
    const stories_abi = await stories_abiResponse.json();
    const storiesminter_abi = await storiesminter_abiResponse.json();
    const configResponse = await fetch("/config/configv2.json", {cache: "no-store"}, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const CONFIG = await configResponse.json();
    const web3 = new Web3(RPC_URL);

      Web3EthContract.setProvider(RPC_URL);
      try {
          const rewards_SmartContractObj = new Web3EthContract(
            rewards_abi,
            CONFIG.REWARDS_CONTRACT_ADDRESS
          );
          const pixiejars_SmartContractObj = new Web3EthContract(
            pixiejars_abi,
            CONFIG.PIXIE_JARS_CONTRACT_ADDRESS
          );
          const pandies_SmartContractObj = new Web3EthContract(
            pixiejars_abi,
            CONFIG.PANDIES_CONTRACT_ADDRESS
          );
          const fawnies_SmartContractObj = new Web3EthContract(
            fawnies_abi,
            CONFIG.FAWNIES_CONTRACT_ADDRESS
          );
          const walletofowner_SmartContractObj = new Web3EthContract(
            walletofowner_abi,
            CONFIG.WALLET_OF_OWNER_CONTRACT_ADDRESS
          );
          const companionjars_SmartContractObj = new Web3EthContract(
            companionjars_abi,
            CONFIG.COMPANION_JARS_CONTRACT_ADDRESS
          );
          const pixiedust_SmartContractObj = new Web3EthContract(
            pixiedust_abi,
            CONFIG.PIXIE_DUST_CONTRACT_ADDRESS
          );
          const stories_SmartContractObj = new Web3EthContract(
            stories_abi,
            CONFIG.PIXIE_JARS_STORIES_CONTRACT_ADDRESS
          );
          const storiesminter_SmartContractObj = new Web3EthContract(
            storiesminter_abi,
            CONFIG.PIXIE_JARS_STORIES_MINTER_CONTRACT_ADDRESS
          );
          dispatch(
            lightConnectSuccess({
              rewardsContract: rewards_SmartContractObj,
              pixiejarsContract: pixiejars_SmartContractObj,
              companionjarsContract: companionjars_SmartContractObj,
              pandiesContract: pandies_SmartContractObj,
              fawniesContract: fawnies_SmartContractObj,
              pixiedustContract: pixiedust_SmartContractObj,
              walletOfOwnerContract: walletofowner_SmartContractObj,
              storiesContract: stories_SmartContractObj,
              storiesMinterContract: storiesminter_SmartContractObj,
              web3: web3,
            })
          );
          dispatch(fetchStories());
      } catch (err) {
        dispatch(connectFailed("Something went wrong."));
      }
    }
};

export const connect = () => {
  return async (dispatch) => {
    dispatch(connectRequest());
    const rewards_abiResponse = await fetch("/config/rewards_abi.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const pixiejars_abiResponse = await fetch("/config/pixie_jars_abi.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const fawnies_abiResponse = await fetch("/config/fawnies_abi.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const walletofowner_abiResponse = await fetch("/config/walletofowner_abi.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const companionjars_abiResponse = await fetch("/config/companion_jars_abi.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const pixiedust_abiResponse = await fetch("/config/pixie_dust_abi.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const stories_abiResponse = await fetch("/config/stories_abi.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const storiesminter_abiResponse = await fetch("/config/stories_minter_abi.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const rewards_abi = await rewards_abiResponse.json();
    const pixiejars_abi = await pixiejars_abiResponse.json();
    const fawnies_abi = await fawnies_abiResponse.json();
    const walletofowner_abi = await walletofowner_abiResponse.json();
    const companionjars_abi = await companionjars_abiResponse.json();
    const pixiedust_abi = await pixiedust_abiResponse.json();
    const stories_abi = await stories_abiResponse.json();
    const storiesminter_abi = await storiesminter_abiResponse.json();
    const configResponse = await fetch("/config/configv2.json", {cache: "no-store"}, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const CONFIG = await configResponse.json();
    web3Modal.clearCachedProvider();
    const provider = await web3Modal.connect();
    const web3 = new Web3(provider);

    const { ethereum } = web3;
      Web3EthContract.setProvider(provider);
      try {
        const accounts = await web3.eth.getAccounts();
        const networkId = await web3.eth.net.getId();
        if (networkId == CONFIG.NETWORK.ID) {
          const rewards_SmartContractObj = new Web3EthContract(
            rewards_abi,
            CONFIG.REWARDS_CONTRACT_ADDRESS
          );
          const pixiejars_SmartContractObj = new Web3EthContract(
            pixiejars_abi,
            CONFIG.PIXIE_JARS_CONTRACT_ADDRESS
          );
          const pandies_SmartContractObj = new Web3EthContract(
            pixiejars_abi,
            CONFIG.PANDIES_CONTRACT_ADDRESS
          );
          const fawnies_SmartContractObj = new Web3EthContract(
            fawnies_abi,
            CONFIG.FAWNIES_CONTRACT_ADDRESS
          );
          const walletofowner_SmartContractObj = new Web3EthContract(
            walletofowner_abi,
            CONFIG.WALLET_OF_OWNER_CONTRACT_ADDRESS
          );
          const companionjars_SmartContractObj = new Web3EthContract(
            companionjars_abi,
            CONFIG.COMPANION_JARS_CONTRACT_ADDRESS
          );
          const pixiedust_SmartContractObj = new Web3EthContract(
            pixiedust_abi,
            CONFIG.PIXIE_DUST_CONTRACT_ADDRESS
          );
          const stories_SmartContractObj = new Web3EthContract(
            stories_abi,
            CONFIG.PIXIE_JARS_STORIES_CONTRACT_ADDRESS
          );
          const storiesminter_SmartContractObj = new Web3EthContract(
            storiesminter_abi,
            CONFIG.PIXIE_JARS_STORIES_MINTER_CONTRACT_ADDRESS
          );
          dispatch(
            connectSuccess({
              account: accounts[0],
              rewardsContract: rewards_SmartContractObj,
              pixiejarsContract: pixiejars_SmartContractObj,
              companionjarsContract: companionjars_SmartContractObj,
              pandiesContract: pandies_SmartContractObj,
              fawniesContract: fawnies_SmartContractObj,
              pixiedustContract: pixiedust_SmartContractObj,
              walletOfOwnerContract: walletofowner_SmartContractObj,
              storiesContract: stories_SmartContractObj,
              storiesMinterContract: storiesminter_SmartContractObj,
              web3: web3,
            })
          );
          // Add listeners start
          provider.on("accountsChanged", (accounts) => {
            dispatch(updateAccount(accounts[0]));
          });
          provider.on("chainChanged", () => {
            window.location.reload();
          });
          // Add listeners end
        } else {
          dispatch(connectFailed(`Change network to ${CONFIG.NETWORK.NAME}.`));
        }
      } catch (err) {
        console.log(err);
        dispatch(connectFailed("Something went wrong."));
      }
    }
};

export const updateAccount = (account) => {
  return async (dispatch) => {
    dispatch(updateAccountRequest({ account: account }));
    dispatch(fetchRewards(account));
  };
};
