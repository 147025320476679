import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect, lightConnect } from "./redux/blockchain/blockchainActions";
import { fetchRewards } from "./redux/rewards/rewardsActions";
import { fetchCheckApprovals, fetchCompanions } from "./redux/companions/companionsActions";
import { fetchDust, fetchCheckPixieClaimed, fetchDustBalance } from "./redux/dust/dustActions";
import { fetchFawnies } from "./redux/fawnies/fawniesActions";
import { fetchStories } from "./redux/stories/storiesActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import Web3 from "web3";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 15px;
  border: none;
  background-color: var(--secondary);
  padding: 5px 10px 5px 10px;
  margin: 10px;
  font-size: 20px;
  font-family: "CloneRounded";
  color: var(--secondary-text);
  width: 120px;
  height: 60px;
  cursor: pointer;
`;
export const NavButton = styled.button`
  padding: 10px;
  border-radius: 15px;
  border: none;
  background-color: var(--nav-button);
  padding: 5px 10px 5px 10px;
  margin: 5px;
  font-size: 20px;
  font-family: "CloneRounded";
  color: var(--nav-button-text);
  width: 160px;
  height: 60px;
  cursor: pointer;
`;

export const StoriesMintButton = styled.button`
  padding: 10px;
  border-radius: 15px;
  border: none;
  background-color: var(--nav-button-stories);
  padding: 5px 10px 5px 10px;
  font-size: 24px;
  font-weight: bold;
  font-family: "CloneRounded";
  color: var(--secondary-text);
  width: 160px;
  height: 60px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledBuyButton = styled.button`
  padding: 10px;
  border-radius: 15px;
  border: none;
  background-color: var(--secondary);
  padding: 5px 10px 5px 10px;
  font-size: 18px;
  font-weight: bold;
  font-family: "CloneRounded";
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 20px;
  border-radius: 100%;
  border: none;
  background-color: var(--primary);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--accent-text);
  width: 50px;
  height: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 300px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledAlternateLogo = styled.img`
  width: 600px;
  @media (min-width: 767px) {
    width: 800px;
  }
  position: relative;
  top: -24px;
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 4px dashed var(--secondary);
  background-color: var(--accent);
  border-radius: 100%;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledStoryImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  background-color: var(--accent);
  border-radius: 15px;
  margin: 15px;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const RotateContainer = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  background-color: ${({ test }) => (test ? "pink" : "none")};
  flex-wrap: ${({ fw }) => (fw ? fw : "nowrap")};
  width: 100%;
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
  transition: width 0.5s;
  &:hover {
    transform: ${({ tf }) => (tf ? tf : "rotate(5deg)")};
    transform-origin: ${({ to }) => (to ? to : "center center")};
    transition: all 0.3s ease-in-out 0s;
  }
`;

export const PixieMask = styled.img`
  position: relative;
  width: 210px;
  height: 300px;
  top: 0px;
  left: -105px;
  @media (min-width: 900px) {
    width: 210px;
    height: 300px;
    top: 0px;
    left: -105px;
  }
  @media (min-width: 1260px) {
    width: 315px;
    height: 450px;
    top: 0px;
    left: -157px;
  }
  transition: width 0.5s;
`;

export const MaskedPixie = styled.img`
  position: relative;
  width: 200px;
  height: 200px;
  top: -30px;
  left: 100px;
  @media (min-width: 900px) {
    width: 200px;
    height: 200px;
    top: -30px;
    left: 100px;
  }
  @media (min-width: 1260px) {
    width: 300px;
    height: 300px;
    top: -45px;
    left: 150px;
  }
  transition: width 0.5s;
`;

export const PlusSign = styled.img`
  margin-top: 30px;
  position: relative;
  width: 60px;
  height: 75px;
  top: -20px;
  left: 0px;
  @media (min-width: 500px) {
    margin-top: 30px;
    left: 20px;
  }
  @media (min-width: 615px) {
    margin-top: 0px;
    left: -60px;
  }
  @media (min-width: 900px) {
    margin-top: 0px;
    width: 60px;
    height: 75px;
    top: -20px;
    left: -60px;
  }
  @media (min-width: 1260px) {
    margin-top: 0px;
    width: 90px;
    height: 113px;
    top: - 30px;
    left: -90px;
  }
  transition: width 0.5s;
  &:hover {
    transform: rotate(5deg);
    transition: all 0.3s ease-in-out 0s;
  }
`;

export const MaskedCompanion = styled.img`
  position: relative;
  margin-top: 30px;
  width: 100px;
  height: 100px;
  top: 0px;
  left: 70px;
  @media (min-width: 600px) {
    left: 70px;
  }
  @media (min-width: 900px) {
    margin-top: 0px;
    width: 100px;
    height: 100px;
    top: 0px;
    left: -25px;
  }
  @media (min-width: 1260px) {
    margin-top: 0px;
    width: 150px;
    height: 150px;
    top: 0px;
    left: -50px;
  }
  transition: width 0.5s;
`;

export const CompanionMask = styled.img`
  margin-top: 30px;
  position: relative;
  width: 120px;
  height: 120px;
  top: 0px;
  left: -30px;
  @media (min-width: 600px) {
    left: -30px;
  }
  @media (min-width: 900px) {
    margin-top: 0px;
    width: 120px;
    height: 120px;
    top: 0px;
    left: -125px;
  }
  @media (min-width: 1260px) {
    margin-top: 0px;
    width: 180px;
    height: 180px;
    top: 0px;
    left: -200px;
  }
  transition: width 0.5s;
`;

export const MaskedCombined = styled.img`
  position: relative;
  width: 200px;
  height: 200px;
  top: 0px;
  left: 100px;
  @media (min-width: 600px) {
    width: 200px;
    height: 200px;
    top: 0px;
    left: 200px;
  }
  @media (min-width: 720px) {
    width: 200px;
    height: 200px;
    top: 0px;
    left: 170px;
  }
  @media (min-width: 900px) {
    width: 200px;
    height: 200px;
    top: 0px;
    left: 170px;
  }
  @media (min-width: 1260px) {
    width: 300px;
    height: 300px;
    top: 0px;
    left: 255px;
  }
  transition: width 0.5s;
`;

export const CombinedMask = styled.img`
  position: relative;
  width: 200px;
  height: 200px;
  top: 0px;
  left: -100px;
  @media (min-width: 600px) {
    width: 200px;
    height: 200px;
    top: 0px;
    left: 0px;
  }
  @media (min-width: 720px) {
    width: 200px;
    height: 200px;
    top: 0px;
    left: -30px;
  }
  @media (min-width: 900px) {
    width: 200px;
    height: 200px;
    top: 0px;
    left: -30px;
  }
  @media (min-width: 1260px) {
    width: 300px;
    height: 300px;
    top: 0px;
    left: -45px;
  }
  transition: width 0.5s;
`;

export const EqualsSign = styled.img`
  position: relative;
  width: 100px;
  height: 50px;
  top: -20px;
  left: -270px;
  display: none;
  @media (min-width: 600px) {
    width: 100px;
    height: 50px;
    top: -20px;
    left: -270px;
    display: block;
  }
  @media (min-width: 720px) {
    width: 100px;
    height: 50px;
    top: -20px;
    left: -320px;
    display: block;
  }
  @media (min-width: 900px) {
    width: 100px;
    height: 50px;
    top: -20px;
    left: -320px;
    display: block;
  }
  @media (min-width: 1260px) {
    width: 150px;
    height: 75px;
    top: -30px;
    left: -480px;
    display: block;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  width: 100%;
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  @media (min-width: 1500px) {
    width: 600px;
  }
`;

export const NavContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  flex-wrap: wrap;
  width: 100%;
  @media (min-width: 1500px) {
    width: 900px;
    justify-content: flex-end;
  }
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const rewards = useSelector((state) => state.rewards);
  const companions = useSelector((state) => state.companions);
  const fawnies = useSelector((state) => state.fawnies);
  const dust = useSelector((state) => state.dust);
  const stories = useSelector((state) => state.stories);
  const [claimingNft, setClaimingNft] = useState(false);
  const [rewardsFeedback, setRewardsFeedback] = useState(``);
  const [storiesFeedback, setStoriesFeedback] = useState(Array(1000).fill(``));
  const [companionsFeedback, setCompanionsFeedback] = useState(``);
  const [fawniesFeedback, setFawniesFeedback] = useState(``);
  const [dustFeedback, setDustFeedback] = useState(``);
  const [currentPage, setCurrentPage] = useState('Home');
  const [selectedPixieID, setSelectedPixieID] = useState(0);
  const [selectedCompanionID, setSelectedCompanionID] = useState(0);
  const [selectedCompanionType, setSelectedCompanionType] = useState('');
  const [currentCompanionID, setCurrentCompanionID] = useState(0);
  const [currentCompanionType, setCurrentCompanionType] = useState('');
  const [combinedSuccess, setCombinedSuccess] = useState(false);
  const [paidMintCount, setPaidMintCount] = useState(0);
  const [batchCollection, setBatchCollection] = useState(Array(10).fill(""));
  const [batchPixieID, setBatchPixieID] = useState(Array(10).fill(0));
  const [batchCompanionID, setBatchCompanionID] = useState(Array(10).fill(0));
  const [storiesRecipeMultiplier, setStoriesRecipeMultiplier] = useState(Array(1000).fill(5));
  const [CONFIG, SET_CONFIG] = useState({
    REWARDS_CONTRACT_ADDRESS: "0x4483AF27e73FCD79B6264b7960ff3209d1357B70",
    PIXIE_JARS_CONTRACT_ADDRESS: "0xeA508034fCC8eefF24bF43effe42621008359A2E",
    PANDIES_CONTRACT_ADDRESS: "0xB036c9088CC5ACf4A6F3a3D9bd4A6Bc65B41C393",
    FAWNIES_CONTRACT_ADDRESS: "0x2654F261D46c4d0EB5dDac81A06D0d1e74fa5dB5",
    WALLET_OF_OWNER_CONTRACT_ADDRESS: "0xed335ACe9bf98B6805551Af4Cd8549b4c682E588",
    COMPANION_JARS_CONTRACT_ADDRESS: "0x01bE37141124d71C0917cFe4Ba9513C064E26F47",
    PIXIE_DUST_CONTRACT_ADDRESS: "0xEDDfCefDf63a50845a05d0765FEdbB8726884D2E",
    PIXIE_JARS_STORIES_CONTRACT_ADDRESS: "0x2800614644f22A94dc723679c2224c3cBa347215",
    PIXIE_JARS_STORIES_MINTER_CONTRACT_ADDRESS: "0xad1b77a454Eb99454a7215148a375E12D3245f2A",
    REWARDS_SCAN_LINK: "https://etherscan.com/address/0x4483AF27e73FCD79B6264b7960ff3209d1357B70",
    FAWNIES_SCAN_LINK: "https://etherscan.com/address/0x2654F261D46c4d0EB5dDac81A06D0d1e74fa5dB5",
    NETWORK: {
      NAME: "Ethereum",
      SYMBOL: "ETH",
      ID: 1,
    },
    NFT_NAME: "Pixie Jars Rewards",
    SYMBOL: "PJR",
    MARKETPLACE: "OpenSea",
    MARKETPLACE_LINK: "https://opensea.io/collection/pixie-jars-rewards",
    SHOW_BACKGROUND: true,
  });

  
  /*
  const setupStories = () => {
      blockchain.storiesMinterContract.methods
        .addRecipe(2140000,1675738800,1675825200,0,[0],[1],[],[])
        .send({
          to: CONFIG.PIXIE_JARS_STORIES_MINTER_CONTRACT_ADDRESS,
          from: blockchain.account,
        })
        .once("error", (err) => {
        })
        .then((receipt) => {
        });
        blockchain.storiesMinterContract.methods
          .addRecipe(0,1675825200,1675911600,0,[1],[1],[0],[1])
          .send({
            to: CONFIG.PIXIE_JARS_STORIES_MINTER_CONTRACT_ADDRESS,
            from: blockchain.account,
          })
          .once("error", (err) => {
          })
          .then((receipt) => {
          });
          blockchain.storiesMinterContract.methods
            .addRecipe(0,1675911600,1675998000,0,[2],[1],[1],[1])
            .send({
              to: CONFIG.PIXIE_JARS_STORIES_MINTER_CONTRACT_ADDRESS,
              from: blockchain.account,
            })
            .once("error", (err) => {
            })
            .then((receipt) => {
            });
            blockchain.storiesMinterContract.methods
              .addRecipe(0,1675998000,1676084400,0,[3],[1],[2],[1])
              .send({
                to: CONFIG.PIXIE_JARS_STORIES_MINTER_CONTRACT_ADDRESS,
                from: blockchain.account,
              })
              .once("error", (err) => {
              })
              .then((receipt) => {
              });
              blockchain.storiesMinterContract.methods
                .addRecipe(0,1676084400,1676170800,0,[4],[1],[3],[1])
                .send({
                  to: CONFIG.PIXIE_JARS_STORIES_MINTER_CONTRACT_ADDRESS,
                  from: blockchain.account,
                })
                .once("error", (err) => {
                })
                .then((receipt) => {
                });
                blockchain.storiesMinterContract.methods
                  .addRecipe(0,1676170800,1676257200,0,[5],[1],[4],[1])
                  .send({
                    to: CONFIG.PIXIE_JARS_STORIES_MINTER_CONTRACT_ADDRESS,
                    from: blockchain.account,
                  })
                  .once("error", (err) => {
                  })
                  .then((receipt) => {
                  });
                  blockchain.storiesMinterContract.methods
                    .addRecipe(0,1676257200,1676343600,0,[6],[1],[0,1,2,3,4,5],[1,1,1,1,1,1])
                    .send({
                      to: CONFIG.PIXIE_JARS_STORIES_MINTER_CONTRACT_ADDRESS,
                      from: blockchain.account,
                    })
                    .once("error", (err) => {
                    })
                    .then((receipt) => {
                    });
                    blockchain.storiesMinterContract.methods
                      .addRecipe(0,1676257200,1676343600,25,[7],[1],[0,1,2,3,4,5],[1,1,1,1,1,1])
                      .send({
                        to: CONFIG.PIXIE_JARS_STORIES_MINTER_CONTRACT_ADDRESS,
                        from: blockchain.account,
                      })
                      .once("error", (err) => {
                      })
                      .then((receipt) => {
                      });
  };*/
  
  const claimCampaign = () => {
    setRewardsFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    
    try { 
      blockchain.rewardsContract.methods
        .claimCampaignReward()
        .send({
          to: CONFIG.REWARDS_CONTRACT_ADDRESS,
          from: blockchain.account,
        })
        .once("error", (err) => {
          console.log(err);
          setRewardsFeedback("Sorry, something went wrong please try again later.");
          setClaimingNft(false);
        })
        .then((receipt) => {
          console.log(receipt);
          setRewardsFeedback(
            `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
          );
          setClaimingNft(false);
          dispatch(fetchRewards(blockchain.account));
        });
      } catch (err) {
        console.log(err);
        setRewardsFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      }
    
  };

  
  const claimSnapshot = () => {
    if((rewards.merkleRoot.toString('hex')) != ("0x" + rewards.calculatedRoot.toString('hex'))) {
      setRewardsFeedback(`Merkle root mismatch between contract and dApp, please try again later.`);
      setClaimingNft(false);
      return;
    }
    
    setRewardsFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    
    try { 
      blockchain.rewardsContract.methods
        .claimSnapshotReward(rewards.snapshotClaimTokenID, rewards.merkleProof)
        .send({
          to: CONFIG.REWARDS_CONTRACT_ADDRESS,
          from: blockchain.account,
        })
        .once("error", (err) => {
          console.log(err);
          setRewardsFeedback("Sorry, something went wrong please try again later.");
          setClaimingNft(false);
        })
        .then((receipt) => {
          console.log(receipt);
          setRewardsFeedback(
            `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
          );
          setClaimingNft(false);
          dispatch(fetchRewards(blockchain.account));
        });
      } catch (err) {
        console.log(err);
        setRewardsFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      }
    
  };

  
  const claimStakingDust = () => {
    setDustFeedback(`Claiming pixie dust...`);
    setClaimingNft(true);
    
    try { 
      blockchain.companionjarsContract.methods
        .claimDust()
        .send({
          to: CONFIG.COMPANION_JARS_CONTRACT_ADDRESS,
          from: blockchain.account,
        })
        .once("error", (err) => {
          console.log(err);
          setDustFeedback("Sorry, something went wrong please try again later.");
          setClaimingNft(false);
        })
        .then((receipt) => {
          console.log(receipt);
          setDustFeedback(
            `Pixie Dust claimed.`
          );
          setClaimingNft(false);
          dispatch(fetchDust(blockchain.account));
        });
      } catch (err) {
        console.log(err);
        setDustFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      }    
  };

  
  const claimDustFromPixies = () => {
    
    setDustFeedback(`Claiming pixie dust...`);
    setClaimingNft(true);
    
    try { 
      blockchain.pixiedustContract.methods
        .claimDust(dust.unclaimedPixies)
        .send({
          to: CONFIG.PIXIE_DUST_CONTRACT_ADDRESS,
          from: blockchain.account,
        })
        .once("error", (err) => {
          console.log(err);
          setDustFeedback("Sorry, something went wrong please try again later.");
          setClaimingNft(false);
        })
        .then((receipt) => {
          console.log(receipt);
          setDustFeedback(
            `Pixie Dust claimed.`
          );
          setClaimingNft(false);
          dispatch(fetchDust(blockchain.account));
        });
      } catch (err) {
        console.log(err);
        setDustFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      }    
  };

  
  const setApprovalForCompanionJars = (from) => {
    setCompanionsFeedback(`Setting approvals...`);
    setClaimingNft(true);
    
    try { 
      let contractToUse = null;
      if(from == CONFIG.PIXIE_JARS_CONTRACT_ADDRESS) { contractToUse = blockchain.pixiejarsContract; }
      else if(from == CONFIG.PANDIES_CONTRACT_ADDRESS) { contractToUse = blockchain.pandiesContract; }
      else if(from == CONFIG.FAWNIES_CONTRACT_ADDRESS) { contractToUse = blockchain.fawniesContract; }
      contractToUse.methods
        .setApprovalForAll(CONFIG.COMPANION_JARS_CONTRACT_ADDRESS, true)
        .send({
          to: from,
          from: blockchain.account,
        })
        .once("error", (err) => {
          console.log(err);
          setCompanionsFeedback("Sorry, something went wrong please try again later.");
          setClaimingNft(false);
        })
        .then((receipt) => {
          console.log(receipt);
          setCompanionsFeedback(
            `Approval set.`
          );
          setClaimingNft(false);
          dispatch(fetchCheckApprovals(blockchain.account));
        });
      } catch (err) {
        console.log(err);
        setCompanionsFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      }    
  };
  
  const combineJarAndCompanion = () => {
    setCompanionsFeedback(`Combining...`);
    setClaimingNft(true);
    
    if(companions.pixieJars.includes(selectedPixieID)) {
      try { 
        blockchain.companionjarsContract.methods
          .stakePixie([selectedPixieID], [(selectedCompanionType == "Pandies" ? CONFIG.PANDIES_CONTRACT_ADDRESS : CONFIG.FAWNIES_CONTRACT_ADDRESS)], [selectedCompanionID])
          .send({
            to: CONFIG.COMPANION_JARS_CONTRACT_ADDRESS,
            from: blockchain.account,
          })
          .once("error", (err) => {
            console.log(err);
            setCompanionsFeedback("Sorry, something went wrong please try again later.");
            setClaimingNft(false);
          })
          .then((receipt) => {
            console.log(receipt);
            setCompanionsFeedback(
              `Success! Your companion is in the jar.`
            );
            setCombinedSuccess(true);
            setClaimingNft(false);
          });
        } catch (err) {
          console.log(err);
          setCompanionsFeedback("Sorry, something went wrong please try again later.");
          setClaimingNft(false);
        }
    } else {
      try { 
        blockchain.companionjarsContract.methods
          .swapCompanion([selectedPixieID], [(selectedCompanionType == "Pandies" ? CONFIG.PANDIES_CONTRACT_ADDRESS : CONFIG.FAWNIES_CONTRACT_ADDRESS)], [selectedCompanionID])
          .send({
            to: CONFIG.COMPANION_JARS_CONTRACT_ADDRESS,
            from: blockchain.account,
          })
          .once("error", (err) => {
            console.log(err);
            setCompanionsFeedback("Sorry, something went wrong please try again later.");
            setClaimingNft(false);
          })
          .then((receipt) => {
            console.log(receipt);
            setCompanionsFeedback(
              `Success! Your companion is in the jar.`
            );
            setCombinedSuccess(true);
            setClaimingNft(false);
          });
        } catch (err) {
          console.log(err);
          setCompanionsFeedback("Sorry, something went wrong please try again later.");
          setClaimingNft(false);
        }
    }
  };
  
  const batchCombineJarAndCompanion = () => {
    let pixieIDs = [];
    let collections = [];
    let companionIDs = [];

    for(let i = 0;i < batchCollection.length;i++) {
      if(
          companions.pixieJars.includes(batchPixieID[i]) && 
          (
            (batchCollection[i] == CONFIG.PANDIES_CONTRACT_ADDRESS && companions.pandies.includes(batchCompanionID[i])) ||
            (batchCollection[i] == CONFIG.FAWNIES_CONTRACT_ADDRESS && companions.fawnies.includes(batchCompanionID[i]))
          )
        ) {
        pixieIDs.push(batchPixieID[i]);
        collections.push(batchCollection[i]);
        companionIDs.push(batchCompanionID[i]);
      }
    }
    
    setCompanionsFeedback(`Combining...`);
    setClaimingNft(true);
    
    if(pixieIDs.length > 0) {
      try { 
        blockchain.companionjarsContract.methods
          .stakePixie(pixieIDs, collections, companionIDs)
          .send({
            to: CONFIG.COMPANION_JARS_CONTRACT_ADDRESS,
            from: blockchain.account,
          })
          .once("error", (err) => {
            console.log(err);
            setCompanionsFeedback("Sorry, something went wrong please try again later.");
            setClaimingNft(false);
          })
          .then((receipt) => {
            console.log(receipt);
            setCompanionsFeedback(
              `Success! Your companion is in the jar.`
            );
            setCombinedSuccess(true);
            setClaimingNft(false);
          });
        } catch (err) {
          console.log(err);
          setCompanionsFeedback("Sorry, something went wrong please try again later.");
          setClaimingNft(false);
        }
    } else {
      setCompanionsFeedback("No valid selections to combine.");
      setClaimingNft(false);
    }
  };
  
  const splitCompanionFromJar = () => {
    setCompanionsFeedback(`Separating...`);
    setClaimingNft(true);
    
    try { 
      blockchain.companionjarsContract.methods
        .unstakePixie([selectedPixieID])
        .send({
          to: CONFIG.COMPANION_JARS_CONTRACT_ADDRESS,
          from: blockchain.account,
        })
        .once("error", (err) => {
          console.log(err);
          setCompanionsFeedback("Sorry, something went wrong please try again later.");
          setClaimingNft(false);
        })
        .then((receipt) => {
          console.log(receipt);
          setCompanionsFeedback(
            `Success! Your companion has been removed from the jar.`
          );
          setCombinedSuccess(true);
          setClaimingNft(false);
        });
      } catch (err) {
        console.log(err);
        setCompanionsFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      }
  };


    
  const mintFawnies = () => {
    if((fawnies.maxClaim <= fawnies.claimed) && paidMintCount == 0) {
      setFawniesFeedback(`You have no Fawnies available to claim and none set to purchase.`);
      setClaimingNft(false);
      return;
    }
    setFawniesFeedback(`Minting your Fawnies...`);
    setClaimingNft(true);
    
    if(fawnies.maxClaim <= fawnies.claimed && fawnies.publicMintOpen) {
      try { 
        console.log(fawnies.merkleProof);
        blockchain.fawniesContract.methods
          .mint(paidMintCount)
          .send({
            to: CONFIG.FAWNIES_CONTRACT_ADDRESS,
            from: blockchain.account,
            value: (fawnies.publicMintPrice * paidMintCount),
          })
          .once("error", (err) => {
            console.log(err);
            setFawniesFeedback("Sorry, something went wrong please try again later.");
            setClaimingNft(false);
          })
          .then((receipt) => {
            console.log(receipt);
            setFawniesFeedback(
              `The Fawnie is yours! Visit OpenSea.io to view it.`
            );
            setClaimingNft(false);
            dispatch(fetchFawnies(blockchain.account));
          });
        } catch (err) {
          console.log(err);
          setFawniesFeedback("Sorry, something went wrong please try again later.");
          setClaimingNft(false);
        }
    } else {
      if((fawnies.merkleRoot.toString('hex')) != ("0x" + fawnies.calculatedRoot.toString('hex'))) {
        setFawniesFeedback(`Merkle root mismatch between contract and dApp, please try again later.`);
        setClaimingNft(false);
        return;
      }
      try { 
        console.log(fawnies.merkleProof);
        blockchain.fawniesContract.methods
          .claim(paidMintCount, fawnies.maxClaim, fawnies.merkleProof)
          .send({
            to: CONFIG.FAWNIES_CONTRACT_ADDRESS,
            from: blockchain.account,
            value: (fawnies.publicMintPrice * paidMintCount),
          })
          .once("error", (err) => {
            console.log(err);
            setFawniesFeedback("Sorry, something went wrong please try again later.");
            setClaimingNft(false);
          })
          .then((receipt) => {
            console.log(receipt);
            setFawniesFeedback(
              `The ${CONFIG.NFT_NAME} is yours! Visit OpenSea.io to view it.`
            );
            setClaimingNft(false);
            dispatch(fetchFawnies(blockchain.account));
          });
        } catch (err) {
          console.log(err);
          setFawniesFeedback("Sorry, something went wrong please try again later.");
          setClaimingNft(false);
        }
    }
  };


    
  const mintStory = (recipe, index) => {
    var feedback = "Minting your Story...";
    setStoriesFeedback(storiesFeedback.map((c, i) => { if(i == index) { return feedback; } else { return c; } }));
    setClaimingNft(true);

    let recipeMultiplier = storiesRecipeMultiplier[index];
    let pixieDustCost = recipe.pixieDustCost * recipeMultiplier;
    let mintTokenIds = [];
    let mintTokenQuantities = [];
    let burnTokenIds = [];
    let burnTokenQuantities = [];
    let totalCost = Web3.utils.toWei((recipe.cost * recipeMultiplier).toString(), "gwei");
    for(let i = 0; i < recipe.mintTokenIds.length;i++) {
      mintTokenIds.push(recipe.mintTokenIds[i]);
      mintTokenQuantities.push(recipe.mintTokenQuantities[i]*recipeMultiplier);
    }

    for(let i = 0; i < recipe.burnTokenIds.length;i++) {
      burnTokenIds.push(recipe.burnTokenIds[i]);
      burnTokenQuantities.push(recipe.burnTokenQuantities[i]*recipeMultiplier);
    }
    
    try { 
      blockchain.storiesMinterContract.methods
        .mint(recipe, recipeMultiplier, pixieDustCost, mintTokenIds, mintTokenQuantities, burnTokenIds, burnTokenQuantities)
        .send({
          to: CONFIG.PIXIE_JARS_STORIES_MINTER_CONTRACT_ADDRESS,
          from: blockchain.account,
          value: totalCost,
        })
        .once("error", (err) => {
          console.log(err);
          var feedback = "Sorry, something went wrong please try again later.";
          setStoriesFeedback(storiesFeedback.map((c, i) => { if(i == index) { return feedback; } else { return c; } }));
          setClaimingNft(false);
        })
        .then((receipt) => {
          console.log(receipt);
          var feedback = "Mint successful!";
          setStoriesFeedback(storiesFeedback.map((c, i) => { if(i == index) { return feedback; } else { return c; } }));
          setStoriesRecipeMultiplier(storiesRecipeMultiplier.map((c, i) => { if(i == index) { return 0; } else { return c; } }));
          setClaimingNft(false);
          dispatch(fetchStories(blockchain.account));
          dispatch(fetchDustBalance(blockchain.account));
        });
      } catch (err) {
        console.log(err);
        var feedback = "Sorry, something went wrong please try again later.";
        setStoriesFeedback(storiesFeedback.map((c, i) => { if(i == index) { return feedback; } else { return c; } }));
        setClaimingNft(false);
      }
  };

  const verifyPaidMintCount = (target) => {
    var newPaidMintCount = 1;
    try {
      newPaidMintCount = parseInt(target.value);
      if(isNaN(newPaidMintCount)) { newPaidMintCount = 1; }
      if(newPaidMintCount > 100) { newPaidMintCount = 100; }
      if(newPaidMintCount < 0) { newPaidMintCount = 0; }
      if(data.maxClaim > 0) {
        if(newPaidMintCount > (5000-data.totalSupply)) { newPaidMintCount = 5000-data.totalSupply;}
      } else {
        if(!data.publicMintOpen) { newPaidMintCount = 0; }
        else if(newPaidMintCount > (5000-data.totalSupply-data.holderReserve)) { newPaidMintCount = (5000-data.totalSupply-data.holderReserve); }

        if(newPaidMintCount < 0) { newPaidMintCount = 0; }
      }
    } catch(err) {}
    setPaidMintCount(newPaidMintCount);
  };



  const updateRecipeMultiplier = (e,index) => {
    const multiplier = e.target.value;
    if (!multiplier || multiplier.match(/^\d+$/)) {
      setStoriesRecipeMultiplier(storiesRecipeMultiplier.map((c, i) => { if(i == index) { return multiplier; } else { return c; } }));
    }
  };


  const getRewards = () => {
    if (blockchain.account !== "" && blockchain.rewardsContract !== null) {
      dispatch(fetchRewards(blockchain.account));
    }
  };

  const getCompanions = () => {
    if (blockchain.account !== "" && blockchain.rewardsContract !== null) {
      dispatch(fetchCompanions(blockchain.account));
    }
  };

  const getFawnies = () => {
    if (blockchain.account !== "" && blockchain.fawniesContract !== null) {
      dispatch(fetchFawnies(blockchain.account));
    }
  };

  const getDust = () => {
    if (blockchain.account !== "" && blockchain.pixiedustContract !== null) {
      if(currentPage == 'Stories') {
        dispatch(fetchDustBalance(blockchain.account));
      } else {
        dispatch(fetchDust(blockchain.account));
      }
    }
  };

  const getStories = () => {
    if (blockchain.storiesContract !== null) {
      dispatch(fetchStories(blockchain.account));
    }
  };

  const updatePixieID = (pixieID) => {
    setSelectedPixieID(pixieID);
  };

  const updateCompanionID = (companionType, companionID) => {
    if(companionType.toLowerCase() == CONFIG.FAWNIES_CONTRACT_ADDRESS.toLowerCase()) {
      companionType = "Fawnies";
    } else if(companionType.toLowerCase() == CONFIG.PANDIES_CONTRACT_ADDRESS.toLowerCase()) {
      companionType = "Pandies";
    }
    setSelectedCompanionID(companionID);
    setSelectedCompanionType(companionType);
  };

  const updateCurrentCompanionID = (companionType, companionID) => {
    if(companionType.toLowerCase() == CONFIG.FAWNIES_CONTRACT_ADDRESS.toLowerCase()) {
      companionType = "Fawnies";
    } else if(companionType.toLowerCase() == CONFIG.PANDIES_CONTRACT_ADDRESS.toLowerCase()) {
      companionType = "Pandies";
    }
    setCurrentCompanionID(companionID);
    setCurrentCompanionType(companionType);
  };

  const resetBatch = () => {
    setBatchCollection(Array(10).fill(CONFIG.PANDIES_CONTRACT_ADDRESS));
    setBatchPixieID(Array(10).fill(0));
    setBatchCompanionID(Array(10).fill(0));
  };

  const updateBatchCollection = (num, e) => {
    num = num - 1;
    let tmpCollections = [...batchCollection];
    tmpCollections[num] = e.target.value;
    setBatchCollection(tmpCollections);
  };

  const updateBatchCompanionID = (num, e) => {
    num = num - 1;
    let tmpCompanionIDs = [...batchCompanionID];
    if((companions.fawnies.includes(e.target.value) && batchCollection[num] == CONFIG.FAWNIES_CONTRACT_ADDRESS) || (companions.pandies.includes(e.target.value) && batchCollection[num] == CONFIG.PANDIES_CONTRACT_ADDRESS)) {
      tmpCompanionIDs[num] = e.target.value;
    } else {
      tmpCompanionIDs[num] = 0;
    }
    setBatchCompanionID(tmpCompanionIDs);
  };

  const updateBatchPixieID = (num, e) => {
    num = num - 1;
    let tmpPixieIDs = [...batchPixieID];
    if(companions.pixieJars.includes(e.target.value)) {
      tmpPixieIDs[num] = e.target.value;
    } else {
      tmpPixieIDs[num] = 0;
    }
    setBatchPixieID(tmpPixieIDs);
  };

  const validBurnQuantities = (recipe, index) => {
    if(recipe.burnTokenIds.length > 0) {
      for(let i = 0;i < recipe.burnTokenIds.length;i++) {
        if(stories.tokenBalances[recipe.burnTokenIds[i]] < (recipe.burnTokenQuantities[i]*storiesRecipeMultiplier[index])) { return false; }
      }
    }
    return true;
  };

  const validPixieDust = (recipe, index) => {
    if(recipe.pixieDustCost > 0) {
      if((dust.balance/10**18) < (recipe.pixieDustCost*storiesRecipeMultiplier[index])) { return false; }
    }
    return true;
  };
  

        var isTestnet = "false";
        async function addNetwork(type, networkToSwitch) {
          var eth, netID;
            if (type === 'web3') {
              try {
                if (typeof ethereum !== 'undefined') {
                    eth = new Web3(ethereum);
                } else if (typeof web3 !== 'undefined') {
                    eth = new Web3(web3.givenProvider);
                } else {
                    eth = new Web3(ethereum);
                }
              } catch(err) {}
            }

            if (typeof eth !== 'undefined') {
                var network = 0;
                network = await eth.eth.net.getId();
                netID = network.toString();
                var params;
                if (isTestnet == "false") {
                  if(networkToSwitch == "polygon") {
                    if (netID == "137") {
                        return;
                    } else {
                        params = [{
                            chainId: '0x89',
                            chainName: 'Polygon Mainnet',
                            nativeCurrency: {
                                name: 'MATIC',
                                symbol: 'MATIC',
                                decimals: 18
                            },
                            rpcUrls: ['https://polygon-rpc.com/'],
                            blockExplorerUrls: ['https://polygonscan.com/']
                        }]
                    }

                    window.ethereum.request({ method: 'wallet_addEthereumChain', params })
                        .then(() => console.log('Success'))
                        .catch((error) => console.log("Error", error.message));
                  } else {
                    if (netID == "1") {
                        //alert("Ethereum has already been added to Metamask.");
                        return;
                    } else {
                        params = [{
                            chainId: '0x1'
                        }]
                    }

                    window.ethereum.request({ method: 'wallet_switchEthereumChain', params })
                        .then(() => console.log('Success'))
                        .catch((error) => console.log("Error", error.message));
                  }
                }
            } else {
                //alert('Unable to locate a compatible web3 browser!');
            }
        }

  const getConfig = async () => {
    const configResponse = await fetch("/config/configv2.json", {cache: "no-store"}, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
    dispatch(lightConnect());
  }, []);

  useEffect(() => {

  }, [companions]);

  useEffect(() => {

  }, [stories, dust]);

  useEffect(() => {

  }, [batchCollection, batchCompanionID, batchPixieID]);

  useEffect(() => {
    if(currentPage == 'Rewards') {
      getRewards();
    } else if(currentPage == 'Companions') {
      getCompanions();
    } else if(currentPage == 'Fawnies') {
      getFawnies();
    } else if(currentPage == 'Dust') {
      getDust();
    } else if(currentPage == 'Stories') {
      getDust();
      getStories();
    }
  }, [blockchain.account]);

  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: 24, backgroundColor: (currentPage == 'Companions' || currentPage == 'Companion Batch' ? "var(--primary)" : (currentPage == 'Stories' ? "var(--stories-background)" : "var(--primary)")) }}
        image={(currentPage == 'Stories' ? null : "/config/images/bg.png")}
      >
          <HeaderContainer style={{paddingLeft: 48, paddingRight: 48}}>
          <LogoContainer>
            {currentPage == "Stories" ?
              <>
                <StyledAlternateLogo alt={"logo"} src={"/config/images/stories_logo.png"} />
              </> : 
              <>
                <StyledLogo alt={"logo"} src={"/config/images/logo.png"} />
              </>
            }
          </LogoContainer>
          <NavContainer>
            <NavButton onClick={() => {setCurrentPage('Home')}} style={{backgroundColor: (currentPage == 'Home' ? 'var(--nav-button-selected)' : (currentPage == 'Stories' ? 'var(--nav-button-stories)' : 'var(--nav-button)'))}}>HOME</NavButton>
            <NavButton onClick={() => {getRewards(); setCurrentPage('Rewards')}} style={{backgroundColor: (currentPage == 'Rewards' ? 'var(--nav-button-selected)' : (currentPage == 'Stories' ? 'var(--nav-button-stories)' : 'var(--nav-button)'))}}>REWARDS</NavButton>
            <NavButton onClick={() => {getCompanions(); setCurrentPage('Companions')}} style={{backgroundColor: (currentPage == 'Companions' ? 'var(--nav-button-selected)' : (currentPage == 'Stories' ? 'var(--nav-button-stories)' : 'var(--nav-button)'))}}>COMPANIONS</NavButton>
            <NavButton onClick={() => {getDust(); setCurrentPage('Dust')}} style={{backgroundColor: (currentPage == 'Dust' ? 'var(--nav-button-selected)' : (currentPage == 'Stories' ? 'var(--nav-button-stories)' : 'var(--nav-button)'))}}>DUST</NavButton>
            { true ? null : <NavButton onClick={() => {getStories(); setCurrentPage('Stories')}} style={{backgroundColor: (currentPage == 'Stories' ? 'var(--nav-button-selected-stories)' : 'var(--nav-button)')}}>STORIES</NavButton> }
          </NavContainer>
        </HeaderContainer>
        <ResponsiveWrapper flex={1} style={{ padding: 24 }} test>
          <s.SpacerLarge />
          <s.Container
            flex={2}
            jc={"center"}
            ai={"center"}
            style={{
              backgroundColor: ((false ? '#FFFFFF00' : currentPage == 'Companions' || currentPage == 'Companion Batch' ? "var(--companions-background)" : (currentPage == 'Stories' ? "var(--stories-background)" : "var(--accent)"))),
              padding: 24,
              borderRadius: 24,
              border: ((false ? '0px' :"4px var(--secondary)")),
              boxShadow: ((false ? '0px' : "0px 5px 11px 2px rgba(0,0,0,0.7)")),
            }}
          >
                {(blockchain.account === "" || blockchain.rewardsContract === null) && (currentPage != "Home" && currentPage != "Stories") ? (
                  <s.Container ai={"center"} jc={"center"}>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                        fontSize: "30px"
                      }}
                    >
                      Connect to the {CONFIG.NETWORK.NAME} network
                    </s.TextDescription>
                    <s.SpacerSmall />
                    <StyledButton
                      onClick={(e) => {
                        e.preventDefault();
                        addNetwork('web3', 'ethereum');
                        dispatch(connect());
                        getRewards();
                      }}
                    >
                      CONNECT
                    </StyledButton>
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription style={{ textAlign: "center", color: "var(--accent-text)", }} >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <>
                  {
                    {
                      'Home': 
                        <>
                          <s.Container ai={"center"} jc={"center"} fd={"column"} fw={"wrap"}>
                              <s.TextDescription style={{color: "#FFFFFF", fontSize: "40px"}}>
                                Welcome to Pixie Jars!
                              </s.TextDescription>
                              <s.SpacerSmall />
                              <s.TextDescription style={{color: "#FFFFFF", fontSize: "30px", textAlign: "center"}}>
                                Here you can:
                              </s.TextDescription>
                              <s.SpacerSmall />
                              <s.TextDescription style={{color: "#FFFFFF", fontSize: "30px", textAlign: "center"}}>
                                ✨ Claim your monthly Pixie Jars Rewards (REWARDS)
                              </s.TextDescription>
                              <s.SpacerSmall />
                              <s.TextDescription style={{color: "#FFFFFF", fontSize: "30px", textAlign: "center"}}>
                                ✨ Stake your Pixie Jar with a companion (COMPANIONS)
                              </s.TextDescription>
                              <s.SpacerSmall />
                              <s.TextDescription style={{color: "#FFFFFF", fontSize: "30px", textAlign: "center"}}>
                                ✨ Claim your Pixie Dust (DUST)
                              </s.TextDescription>
                          </s.Container>
                        </>,
                      'Companions': 
                        <>
                          {
                            companions.loading ? 
                            <>
                          <s.Container ai={"center"} jc={"center"} fd={"column"} fw={"wrap"}>
                            <s.Container ai={"center"} jc={"center"} fd={"row"} fw={"wrap"}>
                              <s.TextDescription style={{color: "#FFFFFF", fontSize: "30px"}}>LOADING...</s.TextDescription>
                            </s.Container>
                          </s.Container>
                            </> : 
                            <>
                          <s.Container ai={"center"} jc={"center"} fd={"column"} fw={"wrap"}>
                            <s.TextDescription onClick={() => {getCompanions(); setCurrentPage('Companion Batch')}} style={{textDecoration: "underline"}}>Switch to Batch Mode (beta)</s.TextDescription>
                            <s.Container ai={"center"} jc={"center"} fd={"row"} fw={"wrap"}>
                              {
                                (selectedPixieID == 0) ? 
                                <>
                                  <s.TextDescription style={{fontSize: "20px"}}>{(selectedPixieID == 0 ? <>SELECT A PIXIE JAR</> : <>PIXIE #{selectedPixieID}</>)}</s.TextDescription>
                                </> : 
                                <>
                                  <RotateContainer ai={"center"} jc={"center"} fd="row" fw={"nowrap"} onClick={() => {setSelectedPixieID(0);}}>
                                    <MaskedPixie src={"https://storage.googleapis.com/pixie-jars/pixies/" + selectedPixieID + ".png"} />
                                    <PixieMask src={"/images/pixie_mask.png"} />
                                  </RotateContainer>
                                  <PlusSign src={"/images/plus_sign.png"} />
                                    {
                                      selectedCompanionID != 0 ?
                                      <RotateContainer to={"center left"} tf={"rotate(-5deg)"} ai={"center"} jc={"center"} fd="row" fw={"nowrap"} onClick={() => {setCombinedSuccess(false); setSelectedCompanionID(0);}}>
                                            <MaskedCompanion src={(selectedCompanionType == 'Pandies' ? "https://storage.googleapis.com/pixie-jars/pandies/transparent/" + selectedCompanionID + ".png" : "https://storage.googleapis.com/pixie-jars/fawnies/transparent/" + selectedCompanionID + ".png")} />
                                            <CompanionMask src={"/images/companion_mask.png"} />
                                      </RotateContainer> :
                                      <></>
                                    }
                                </>
                              }
                            </s.Container>
                            <s.Container ai={"center"} jc={"center"} fd="row" fw={"wrap"} style={{display: (selectedPixieID == 0 ? "flex" : "none")}}>
                                {
                                  (companions.pixieJars && companions.pixieJars.length > 0) ? (
                                    <>
                                    { companions.pixieJars.map((obj) => 
                                    
                                      <s.Container ai={"center"} jc={"center"} onClick={() => {updatePixieID(obj);}} style={{padding: "10px", width: "120px", height: "120px", backgroundColor: (obj == selectedPixieID ? "#FFFFFF00" : "#FFFFFF00"), borderRadius: "10px"}}>
                                        <img src={"https://storage.googleapis.com/pixie-jars/pixies/" + obj + ".png"} style={{width: "100px", height: "100px", borderRadius: "5px"}} />
                                        <s.TextDescription>#{obj}</s.TextDescription>
                                      </s.Container>) }
                                    </>
                                    ) : (<></>) 
                                }
                                {
                                  (companions.companionJars && companions.companionJars.length > 0) ? (
                                    <>
                                    { companions.companionJars.map((obj) => 
                                    
                                      <s.Container ai={"center"} jc={"center"} onClick={() => {updatePixieID(obj["pixieTokenId"]); updateCompanionID(obj["companionCollection"], obj["companionTokenId"]); updateCurrentCompanionID(obj["companionCollection"], obj["companionTokenId"]);}} style={{padding: "10px", width: "120px", height: "120px", backgroundColor: (obj == selectedPixieID ? "#FFFFFF00" : "#FFFFFF00"), borderRadius: "10px"}}>
                                        <img src={"https://pixiejars.app/companionjars/image/" + obj["pixieTokenId"] + "/" + obj["companionCollection"] + "/" + obj["companionTokenId"]} style={{width: "100px", height: "100px", borderRadius: "5px"}} />
                                        <s.TextDescription>#{obj["pixieTokenId"]}</s.TextDescription>
                                      </s.Container>) }
                                    </>
                                    ) : (<></>) 
                                }
                            </s.Container>
                            {
                              selectedCompanionID == 0 ? 
                              <>
                                <s.Container ai={"center"} jc={"center"} fd="row" fw={"wrap"} style={{display: (selectedPixieID != 0 ? "flex" : "none")}} onClick={() => {setCombinedSuccess(false); setSelectedCompanionID(0);}}>
                                  <s.TextDescription style={{fontSize: "20px"}}>{(selectedCompanionID == 0 ? <>SELECT A COMPANION</> : <>{selectedCompanionType.toUpperCase()} #{selectedCompanionID}</>)}</s.TextDescription>
                                </s.Container>
                              </> : 
                              <></>
                            }
                            <s.Container ai={"center"} jc={"center"} fd="row" fw={"wrap"} style={{display: ((selectedPixieID != 0 && selectedCompanionID == 0) ? "flex" : "none")}}>
                                {
                                  (companions.pandies && companions.pandies.length > 0) ? (
                                    <>
                                    { companions.pandies.map((obj) => 
                                    
                                      <s.Container ai={"center"} jc={"center"} onClick={() => {updateCompanionID('Pandies',obj);}} style={{padding: "10px", width: "120px", height: "120px", backgroundColor: (obj == selectedCompanionID && selectedCompanionType == 'Pandies' ? "#FFFFFF00" : "#FFFFFF00"), borderRadius: "10px"}}>
                                        <img src={"https://storage.googleapis.com/pixie-jars/pandies/transparent/" + obj + ".png"} style={{width: "100px", height: "100px", borderRadius: "5px"}} />
                                        <s.TextDescription>#{obj}</s.TextDescription>
                                      </s.Container>) }
                                    </>
                                    ) : (<></>) 
                                }
                                {
                                  (companions.fawnies && companions.fawnies.length > 0) ? (
                                    <>
                                    { companions.fawnies.map((obj) => 
                                    
                                      <s.Container ai={"center"} jc={"center"} onClick={() => {updateCompanionID('Fawnies',obj);}} style={{padding: "10px", width: "120px", height: "120px", backgroundColor: (obj == selectedCompanionID && selectedCompanionType == 'Fawnies' ? "#FFFFFF00" : "#FFFFFF00"), borderRadius: "10px"}}>
                                        <img src={"https://storage.googleapis.com/pixie-jars/fawnies/transparent/" + obj + ".png"} style={{width: "100px", height: "100px", borderRadius: "5px"}} />
                                        <s.TextDescription>#{obj}</s.TextDescription>
                                      </s.Container>) }
                                    </>
                                    ) : (<></>) 
                                }
                            </s.Container>
                            <s.SpacerMedium />

                            { selectedPixieID != 0 && selectedCompanionID != 0 ? 
                              <>
                                <RotateContainer tf={"rotate(0deg)"} ai={"center"} jc={"center"} fd="row" fw={"nowrap"}>
                                    <MaskedCombined src={"https://pixiejars.app/companionjars/image/" + selectedPixieID + "/" + (selectedCompanionType == "Pandies" ? CONFIG.PANDIES_CONTRACT_ADDRESS : CONFIG.FAWNIES_CONTRACT_ADDRESS) + "/" + selectedCompanionID + "/temp"} />
                                    <CombinedMask src={"/images/combined_mask.png"} />
                                    <EqualsSign src={"/images/equals_sign.png"} />
                                </RotateContainer>
                                <s.SpacerSmall />
                                <s.Container ai={"center"} jc={"center"} fd="row" fw={"wrap"}>
                                  <StyledButton onClick={(e) => { setCompanionsFeedback(""); updateCurrentCompanionID("", 0); setSelectedCompanionID(0); setSelectedPixieID(0); if(combinedSuccess) { dispatch(fetchCompanions(blockchain.account)); setCombinedSuccess(false); } }} >
                                    {combinedSuccess ? "COMBINE ANOTHER" : "RESET"}
                                  </StyledButton>
                                  {
                                    !companions.pixiesApproved ? 
                                    <>
                                      <s.SpacerSmall />
                                      <StyledButton disabled={claimingNft} onClick={(e) => { setApprovalForCompanionJars(CONFIG.PIXIE_JARS_CONTRACT_ADDRESS); }} >
                                        { claimingNft ? "BUSY" : "APPROVE PIXIE" }
                                      </StyledButton>
                                    </> : 
                                    <>
                                      {selectedCompanionType == "Pandies" && !companions.pandiesApproved ?
                                        <>
                                          <s.SpacerSmall />
                                          <StyledButton disabled={claimingNft} onClick={(e) => { setApprovalForCompanionJars(CONFIG.PANDIES_CONTRACT_ADDRESS); }} >
                                            { claimingNft ? "BUSY" : "APPROVE PANDIE" }
                                          </StyledButton>
                                        </> :
                                        <>
                                          {selectedCompanionType == "Fawnies" && !companions.fawniesApproved ?
                                            <>
                                              <s.SpacerSmall />
                                              <StyledButton disabled={claimingNft} onClick={(e) => { setApprovalForCompanionJars(CONFIG.FAWNIES_CONTRACT_ADDRESS); }} >
                                                { claimingNft ? "BUSY" : "APPROVE FAWNIE" }
                                              </StyledButton>
                                            </> :
                                            <>
                                              {
                                                combinedSuccess || (selectedCompanionID == currentCompanionID && selectedCompanionType == currentCompanionType) ? 
                                                <></> :
                                                <>
                                                  <s.SpacerSmall />
                                                  <StyledButton onClick={(e) => { combineJarAndCompanion(); }} >
                                                    { claimingNft ? "BUSY" : "COMBINE" }
                                                  </StyledButton>
                                                </>
                                              }
                                            </>
                                          }
                                        </>
                                      }
                                    </>
                                  }
                                  {
                                    !companions.pixieJars.includes(selectedPixieID) && !combinedSuccess && (selectedCompanionID == currentCompanionID && selectedCompanionType == currentCompanionType) ?
                                    <>
                                      <s.SpacerSmall />
                                      <StyledButton disabled={claimingNft} onClick={(e) => { splitCompanionFromJar(); }} >
                                        { claimingNft ? "BUSY" : "SPLIT" }
                                      </StyledButton>
                                    </> : 
                                    <></>
                                  }
                                </s.Container>
                                <s.SpacerSmall />
                                <s.TextDescription style={{fontSize: "25px"}}>{companionsFeedback}</s.TextDescription>
                              </> : <></>
                            }
                          </s.Container>
                            </>
                          }
                        </>,
                      'Companion Batch':
                        <>
                          <s.Container ai={"center"} jc={"center"} fd={"column"} fw={"wrap"}>
                            <s.TextDescription onClick={() => {getCompanions(); setCurrentPage('Companions')}} style={{textDecoration: "underline"}}>Switch to Single Mode</s.TextDescription>
                            <s.Container ai={"center"} jc={"center"} fd={"row"} fw={"wrap"}>
                              <input type="text" onChange={(e) => { updateBatchPixieID(1,e);}} />
                              <s.SpacerSmall />
                              <select onChange={(e) => { updateBatchCollection(1,e);}}>
                                <option value="0xB036c9088CC5ACf4A6F3a3D9bd4A6Bc65B41C393">Pandie</option>
                                <option value="0x2654F261D46c4d0EB5dDac81A06D0d1e74fa5dB5">Fawnie</option>
                              </select>
                              <s.SpacerSmall />
                              <input type="text" onChange={(e) => { updateBatchCompanionID(1,e);}} />
                            </s.Container>
                            <s.Container ai={"center"} jc={"center"} fd={"row"} fw={"wrap"}>
                              <input type="text" onChange={(e) => { updateBatchPixieID(2,e);}} />
                              <s.SpacerSmall />
                              <select onChange={(e) => { updateBatchCollection(2,e);}}>
                                <option value="0xB036c9088CC5ACf4A6F3a3D9bd4A6Bc65B41C393">Pandie</option>
                                <option value="0x2654F261D46c4d0EB5dDac81A06D0d1e74fa5dB5">Fawnie</option>
                              </select>
                              <s.SpacerSmall />
                              <input type="text" onChange={(e) => { updateBatchCompanionID(2,e);}} />
                            </s.Container>
                            <s.Container ai={"center"} jc={"center"} fd={"row"} fw={"wrap"}>
                              <input type="text" onChange={(e) => { updateBatchPixieID(3,e);}} />
                              <s.SpacerSmall />
                              <select onChange={(e) => { updateBatchCollection(3,e);}}>
                                <option value="0xB036c9088CC5ACf4A6F3a3D9bd4A6Bc65B41C393">Pandie</option>
                                <option value="0x2654F261D46c4d0EB5dDac81A06D0d1e74fa5dB5">Fawnie</option>
                              </select>
                              <s.SpacerSmall />
                              <input type="text" onChange={(e) => { updateBatchCompanionID(3,e);}} />
                            </s.Container>
                            <s.Container ai={"center"} jc={"center"} fd={"row"} fw={"wrap"}>
                              <input type="text" onChange={(e) => { updateBatchPixieID(4,e);}} />
                              <s.SpacerSmall />
                              <select onChange={(e) => { updateBatchCollection(4,e);}}>
                                <option value="0xB036c9088CC5ACf4A6F3a3D9bd4A6Bc65B41C393">Pandie</option>
                                <option value="0x2654F261D46c4d0EB5dDac81A06D0d1e74fa5dB5">Fawnie</option>
                              </select>
                              <s.SpacerSmall />
                              <input type="text" onChange={(e) => { updateBatchCompanionID(4,e);}} />
                            </s.Container>
                            <s.Container ai={"center"} jc={"center"} fd={"row"} fw={"wrap"}>
                              <input type="text" onChange={(e) => { updateBatchPixieID(5,e);}} />
                              <s.SpacerSmall />
                              <select onChange={(e) => { updateBatchCollection(5,e);}}>
                                <option value="0xB036c9088CC5ACf4A6F3a3D9bd4A6Bc65B41C393">Pandie</option>
                                <option value="0x2654F261D46c4d0EB5dDac81A06D0d1e74fa5dB5">Fawnie</option>
                              </select>
                              <s.SpacerSmall />
                              <input type="text" onChange={(e) => { updateBatchCompanionID(5,e);}} />
                            </s.Container>
                            <s.Container ai={"center"} jc={"center"} fd={"row"} fw={"wrap"}>
                              <input type="text" onChange={(e) => { updateBatchPixieID(6,e);}} />
                              <s.SpacerSmall />
                              <select onChange={(e) => { updateBatchCollection(6,e);}}>
                                <option value="0xB036c9088CC5ACf4A6F3a3D9bd4A6Bc65B41C393">Pandie</option>
                                <option value="0x2654F261D46c4d0EB5dDac81A06D0d1e74fa5dB5">Fawnie</option>
                              </select>
                              <s.SpacerSmall />
                              <input type="text" onChange={(e) => { updateBatchCompanionID(6,e);}} />
                            </s.Container>
                            <s.Container ai={"center"} jc={"center"} fd={"row"} fw={"wrap"}>
                              <input type="text" onChange={(e) => { updateBatchPixieID(7,e);}} />
                              <s.SpacerSmall />
                              <select onChange={(e) => { updateBatchCollection(7,e);}}>
                                <option value="0xB036c9088CC5ACf4A6F3a3D9bd4A6Bc65B41C393">Pandie</option>
                                <option value="0x2654F261D46c4d0EB5dDac81A06D0d1e74fa5dB5">Fawnie</option>
                              </select>
                              <s.SpacerSmall />
                              <input type="text" onChange={(e) => { updateBatchCompanionID(7,e);}} />
                            </s.Container>
                            <s.Container ai={"center"} jc={"center"} fd={"row"} fw={"wrap"}>
                              <input type="text" onChange={(e) => { updateBatchPixieID(8,e);}} />
                              <s.SpacerSmall />
                              <select onChange={(e) => { updateBatchCollection(8,e);}}>
                                <option value="0xB036c9088CC5ACf4A6F3a3D9bd4A6Bc65B41C393">Pandie</option>
                                <option value="0x2654F261D46c4d0EB5dDac81A06D0d1e74fa5dB5">Fawnie</option>
                              </select>
                              <s.SpacerSmall />
                              <input type="text" onChange={(e) => { updateBatchCompanionID(8,e);}} />
                            </s.Container>
                            <s.Container ai={"center"} jc={"center"} fd={"row"} fw={"wrap"}>
                              <input type="text" onChange={(e) => { updateBatchPixieID(9,e);}} />
                              <s.SpacerSmall />
                              <select onChange={(e) => { updateBatchCollection(9,e);}}>
                                <option value="0xB036c9088CC5ACf4A6F3a3D9bd4A6Bc65B41C393">Pandie</option>
                                <option value="0x2654F261D46c4d0EB5dDac81A06D0d1e74fa5dB5">Fawnie</option>
                              </select>
                              <s.SpacerSmall />
                              <input type="text" onChange={(e) => { updateBatchCompanionID(9,e);}} />
                            </s.Container>
                            <s.Container ai={"center"} jc={"center"} fd={"row"} fw={"wrap"}>
                              <input type="text" onChange={(e) => { updateBatchPixieID(10,e);}} />
                              <s.SpacerSmall />
                              <select onChange={(e) => { updateBatchCollection(10,e);}}>
                                <option value="0xB036c9088CC5ACf4A6F3a3D9bd4A6Bc65B41C393">Pandie</option>
                                <option value="0x2654F261D46c4d0EB5dDac81A06D0d1e74fa5dB5">Fawnie</option>
                              </select>
                              <s.SpacerSmall />
                              <input type="text" onChange={(e) => { updateBatchCompanionID(10,e);}} />
                            </s.Container>
                            <s.Container ai={"center"} jc={"center"} fd={"row"} fw={"wrap"}>
                              {
                                (
                                  (batchCollection[0] == CONFIG.FAWNIES_CONTRACT_ADDRESS && companions.fawnies.includes(batchCompanionID[0])) || 
                                  (batchCollection[0] == CONFIG.PANDIES_CONTRACT_ADDRESS && companions.pandies.includes(batchCompanionID[0]))
                                ) && (companions.pixieJars.includes(batchPixieID[0])) ?
                                <>
                                  <img src={"https://pixiejars.app/companionjars/image/" + batchPixieID[0] + "/" + batchCollection[0] + "/" + batchCompanionID[0] + "/temp"} style={{width: "200px", height: "200px", padding: "10px"}} />
                                </> : null
                              }
                              {
                                (
                                  (batchCollection[1] == CONFIG.FAWNIES_CONTRACT_ADDRESS && companions.fawnies.includes(batchCompanionID[1])) || 
                                  (batchCollection[1] == CONFIG.PANDIES_CONTRACT_ADDRESS && companions.pandies.includes(batchCompanionID[1]))
                                ) && (companions.pixieJars.includes(batchPixieID[1])) ?
                                <>
                                  <img src={"https://pixiejars.app/companionjars/image/" + batchPixieID[1] + "/" + batchCollection[1] + "/" + batchCompanionID[1] + "/temp"} style={{width: "200px", height: "200px", padding: "10px"}} />
                                </> : null
                              }
                              {
                                (
                                  (batchCollection[2] == CONFIG.FAWNIES_CONTRACT_ADDRESS && companions.fawnies.includes(batchCompanionID[2])) || 
                                  (batchCollection[2] == CONFIG.PANDIES_CONTRACT_ADDRESS && companions.pandies.includes(batchCompanionID[2]))
                                ) && (companions.pixieJars.includes(batchPixieID[2])) ?
                                <>
                                  <img src={"https://pixiejars.app/companionjars/image/" + batchPixieID[2] + "/" + batchCollection[2] + "/" + batchCompanionID[2] + "/temp"} style={{width: "200px", height: "200px", padding: "10px"}} />
                                </> : null
                              }
                              {
                                (
                                  (batchCollection[3] == CONFIG.FAWNIES_CONTRACT_ADDRESS && companions.fawnies.includes(batchCompanionID[3])) || 
                                  (batchCollection[3] == CONFIG.PANDIES_CONTRACT_ADDRESS && companions.pandies.includes(batchCompanionID[3]))
                                ) && (companions.pixieJars.includes(batchPixieID[3])) ?
                                <>
                                  <img src={"https://pixiejars.app/companionjars/image/" + batchPixieID[3] + "/" + batchCollection[3] + "/" + batchCompanionID[3] + "/temp"} style={{width: "200px", height: "200px", padding: "10px"}} />
                                </> : null
                              }
                              {
                                (
                                  (batchCollection[4] == CONFIG.FAWNIES_CONTRACT_ADDRESS && companions.fawnies.includes(batchCompanionID[4])) || 
                                  (batchCollection[4] == CONFIG.PANDIES_CONTRACT_ADDRESS && companions.pandies.includes(batchCompanionID[4]))
                                ) && (companions.pixieJars.includes(batchPixieID[4])) ?
                                <>
                                  <img src={"https://pixiejars.app/companionjars/image/" + batchPixieID[4] + "/" + batchCollection[4] + "/" + batchCompanionID[4] + "/temp"} style={{width: "200px", height: "200px", padding: "10px"}} />
                                </> : null
                              }
                              {
                                (
                                  (batchCollection[5] == CONFIG.FAWNIES_CONTRACT_ADDRESS && companions.fawnies.includes(batchCompanionID[5])) || 
                                  (batchCollection[5] == CONFIG.PANDIES_CONTRACT_ADDRESS && companions.pandies.includes(batchCompanionID[5]))
                                ) && (companions.pixieJars.includes(batchPixieID[5])) ?
                                <>
                                  <img src={"https://pixiejars.app/companionjars/image/" + batchPixieID[5] + "/" + batchCollection[5] + "/" + batchCompanionID[5] + "/temp"} style={{width: "200px", height: "200px", padding: "10px"}} />
                                </> : null
                              }
                              {
                                (
                                  (batchCollection[6] == CONFIG.FAWNIES_CONTRACT_ADDRESS && companions.fawnies.includes(batchCompanionID[6])) || 
                                  (batchCollection[6] == CONFIG.PANDIES_CONTRACT_ADDRESS && companions.pandies.includes(batchCompanionID[6]))
                                ) && (companions.pixieJars.includes(batchPixieID[6])) ?
                                <>
                                  <img src={"https://pixiejars.app/companionjars/image/" + batchPixieID[6] + "/" + batchCollection[6] + "/" + batchCompanionID[6] + "/temp"} style={{width: "200px", height: "200px", padding: "10px"}} />
                                </> : null
                              }
                              {
                                (
                                  (batchCollection[7] == CONFIG.FAWNIES_CONTRACT_ADDRESS && companions.fawnies.includes(batchCompanionID[7])) || 
                                  (batchCollection[7] == CONFIG.PANDIES_CONTRACT_ADDRESS && companions.pandies.includes(batchCompanionID[7]))
                                ) && (companions.pixieJars.includes(batchPixieID[7])) ?
                                <>
                                  <img src={"https://pixiejars.app/companionjars/image/" + batchPixieID[7] + "/" + batchCollection[7] + "/" + batchCompanionID[7] + "/temp"} style={{width: "200px", height: "200px", padding: "10px"}} />
                                </> : null
                              }
                              {
                                (
                                  (batchCollection[8] == CONFIG.FAWNIES_CONTRACT_ADDRESS && companions.fawnies.includes(batchCompanionID[8])) || 
                                  (batchCollection[8] == CONFIG.PANDIES_CONTRACT_ADDRESS && companions.pandies.includes(batchCompanionID[8]))
                                ) && (companions.pixieJars.includes(batchPixieID[8])) ?
                                <>
                                  <img src={"https://pixiejars.app/companionjars/image/" + batchPixieID[8] + "/" + batchCollection[8] + "/" + batchCompanionID[8] + "/temp"} style={{width: "200px", height: "200px", padding: "10px"}} />
                                </> : null
                              }
                              {
                                (
                                  (batchCollection[9] == CONFIG.FAWNIES_CONTRACT_ADDRESS && companions.fawnies.includes(batchCompanionID[9])) || 
                                  (batchCollection[9] == CONFIG.PANDIES_CONTRACT_ADDRESS && companions.pandies.includes(batchCompanionID[9]))
                                ) && (companions.pixieJars.includes(batchPixieID[9])) ?
                                <>
                                  <img src={"https://pixiejars.app/companionjars/image/" + batchPixieID[9] + "/" + batchCollection[9] + "/" + batchCompanionID[9] + "/temp"} style={{width: "200px", height: "200px", padding: "10px"}} />
                                </> : null
                              }
                            </s.Container>
                            <s.Container ai={"center"} jc={"center"} fd={"row"} fw={"wrap"}>
                                <StyledButton onClick={(e) => { batchCombineJarAndCompanion(); }} >
                                  { claimingNft ? "BUSY" : "COMBINE" }
                                </StyledButton>
                            </s.Container>
                            <s.TextDescription style={{fontSize: "25px"}}>{companionsFeedback}</s.TextDescription>
                            
                          </s.Container>
                        </>,
                      'Dust':
                        <>
                          <>
                            <s.TextTitle style={{textAlign: "center",fontSize: 50,fontWeight: "bold",color: "var(--accent-text)",}}>
                              Pixie Dust
                            </s.TextTitle>
                            <s.SpacerSmall />
                            <s.TextDescription
                              style={{ textAlign: "center", color: "var(--accent-text)", fontSize: "20px" }}
                            >
                              Pixie dust is earned by staking Pixie Jars with companions. You earn one dust per day per pixie staked.<br />All Pixie Jars holders are eligible for a one time claim of 50 Pixie Dust for each jar held.
                            </s.TextDescription>
                            <s.SpacerMedium />
                            <s.TextDescription style={{ textAlign: "center", color: "var(--accent-text)", fontSize: "20px" }}>
                              Pixie Dust Balance: {Math.floor(dust.balance/10**18*100)/100}
                            </s.TextDescription>
                            <s.SpacerSmall />
                            <s.TextDescription style={{ textAlign: "center", color: "var(--accent-text)", fontSize: "20px" }}>
                              Unclaimed Staking Dust: {Math.floor(dust.unclaimedStakingDust/10**18*100)/100}
                            </s.TextDescription>
                            { dust.unclaimedPixies && dust.unclaimedPixies.length > 0 ? 
                              <>
                                <s.SpacerSmall />
                                <s.TextDescription style={{ textAlign: "center", color: "var(--accent-text)", fontSize: "20px" }}>
                                  Unclaimed Pixies Dust: {dust.unclaimedPixies.length * 50} 
                                </s.TextDescription>
                              </> :
                              <></>
                            }
                            <s.SpacerSmall />
                            { false ? null : 
                            <s.Container ai={"center"} jc={"center"} fd={"row"}>
                              <StyledBuyButton
                                disabled={claimingNft || rewards.snapshotClaimed ? 1 : 0}
                                onClick={(e) => {
                                  e.preventDefault();
                                  claimStakingDust();
                                }}
                              >
                                {claimingNft ? "BUSY" : "CLAIM DUST" }
                              </StyledBuyButton>
                              { dust.unclaimedPixies && dust.unclaimedPixies.length > 0 ? 
                                <>
                                  <s.SpacerSmall />
                                  <StyledBuyButton
                                    disabled={claimingNft}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      claimDustFromPixies();
                                    }}
                                  >
                                    {claimingNft ? "BUSY" : "PIXIE CLAIM" }
                                  </StyledBuyButton>
                                </> :
                                <></>
                              }
                            </s.Container>
                            }
                            <s.SpacerSmall />
                            <s.TextDescription
                              style={{
                                textAlign: "center",
                                color: "var(--accent-text)",
                              }}
                            >
                              {dustFeedback}
                            </s.TextDescription>
                          </>
                        </>,
                      'Rewards':
                        <>
                          <>
            <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 50,
                fontWeight: "bold",
                color: "var(--accent-text)",
              }}
            >
              Claim your Pixie Jars Reward!
            </s.TextTitle>
            <s.TextDescription
              style={{
                textAlign: "center",
                color: "var(--primary-text)",
              }}
            >
             Contract Address: <StyledLink target={"_blank"} href={CONFIG.REWARDS_SCAN_LINK}>
                {truncate(CONFIG.REWARDS_CONTRACT_ADDRESS, 15)}
              </StyledLink>
            </s.TextDescription>
            <s.SpacerSmall />
            
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  Rewards are free to claim.
                </s.TextTitle>
                <s.SpacerXSmall />
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  Excluding gas fees.
                </s.TextDescription>
                <s.SpacerSmall />
                            <s.TextDescription
                              style={{
                                textAlign: "center",
                                color: "var(--accent-text)",
                              }}
                            >
                              {rewardsFeedback}
                            </s.TextDescription>
                            <s.SpacerMedium />
                            <s.Container ai={"center"} jc={"center"} fd={"column"}>
                              <s.Container ai={"center"} jc={"center"} fd={"row"}>
                                {(rewards.campaignClaimIsActive && rewards.pjBalance > 0) ? (
                                  <>
                                    <s.TextDescription
                                      style={{
                                        textAlign: "center",
                                        color: "var(--accent-text)",
                                        fontSize: "20px"
                                      }}
                                    >
                                      Monthly Reward: 
                                    </s.TextDescription>
                                    <s.SpacerSmall />
                                    <StyledBuyButton
                                      disabled={claimingNft || rewards.campaignClaimed ? 1 : 0}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        claimCampaign();
                                        getRewards();
                                      }}
                                    >
                                      {claimingNft ? "BUSY" : (rewards.campaignClaimed ? "CLAIMED" : "CLAIM") }
                                    </StyledBuyButton>
                                  </>
                                ) : 
                                (
                                  <>
                                    <s.TextDescription
                                      style={{
                                        textAlign: "center",
                                        color: "var(--accent-text)",
                                        fontSize: "20px"
                                      }}
                                    >
                                      {rewards.pjBalance > 0 ? <>NO ACTIVE MONTHLY REWARD</> : <>MUST OWN PIXIE JARS TO CLAIM</>}
                                    </s.TextDescription>
                                  </>
                                )
                                  
                                }
                              </s.Container>
                              <s.SpacerSmall />
                              <s.Container ai={"center"} jc={"center"} fd={"row"}>
                                {(rewards.snapshotClaimIsActive && rewards.snapshotClaimTokenID != 0) ? (
                                  <>
                                    <s.TextDescription
                                      style={{
                                        textAlign: "center",
                                        color: "var(--accent-text)",
                                        fontSize: "20px"
                                      }}
                                    >
                                      Snapshot Reward: 
                                    </s.TextDescription>
                                    <s.SpacerSmall />
                                    <StyledBuyButton
                                      disabled={claimingNft || rewards.snapshotClaimed ? 1 : 0}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        claimSnapshot();
                                        getRewards();
                                      }}
                                    >
                                      {claimingNft ? "BUSY" : (rewards.snapshotClaimed ? "CLAIMED" : "CLAIM") }
                                    </StyledBuyButton>
                                  </>
                                ) : 
                                (
                                  <>
                                    <s.TextDescription
                                      style={{
                                        textAlign: "center",
                                        color: "var(--accent-text)",
                                        fontSize: "20px"
                                      }}
                                    >
                                      NO ACTIVE SNAPSHOT REWARD
                                    </s.TextDescription>
                                  </>
                                )
                                  
                                }
                              </s.Container>
                            </s.Container>
                          </>
                        </>,
                        'Fawnies': 
                        <>
                          <s.TextDescription
              style={{
                textAlign: "center",
                color: "var(--fawnies-primary-text)",
              }}
            >
                Contract Address: <StyledLink target={"_blank"} href={CONFIG.FAWNIES_SCAN_LINK}>
                    {truncate(CONFIG.FAWNIES_CONTRACT_ADDRESS, 15)}
                  </StyledLink>
                </s.TextDescription>
                <s.SpacerSmall />
                { ((blockchain.account === "" ||
                    blockchain.fawniesContract === null)) ? <>
                      <s.Container ai={"center"} jc={"center"}>
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--fawnies-accent-text)",
                          }}
                        >
                          Connect to the {CONFIG.NETWORK.NAME} network
                        </s.TextDescription>
                        <s.SpacerSmall />
                        <StyledButton
                          onClick={(e) => {
                            e.preventDefault();
                            dispatch(connect());
                            getFawnies();
                          }}
                        >
                          CONNECT
                        </StyledButton>
                        {blockchain.errorMsg !== "" ? (
                          <>
                            <s.SpacerSmall />
                            <s.TextDescription
                              style={{
                                textAlign: "center",
                                color: "var(--fawnies-accent-text)",
                              }}
                            >
                              {blockchain.errorMsg}
                            </s.TextDescription>
                          </>
                        ) : null}
                      </s.Container></> : <>
                    <s.TextTitle
                      style={{ textAlign: "center", color: "var(--fawnies-accent-text)" }}
                    >
                      {(fawnies.maxClaim > 0) ? 
                        <>Fawnies adoptable: {5000-fawnies.totalSupply}</> : 
                        <>Fawnies adoptable: {(5000-fawnies.totalSupply-fawnies.holderReserve) < 0 || !fawnies.publicMintOpen ? 0 : 5000-fawnies.totalSupply-fawnies.holderReserve}</>
                      }
                    </s.TextTitle>
                    <s.SpacerXSmall />
                    <s.TextTitle
                      style={{ textAlign: "center", color: "var(--fawnies-accent-text)" }}
                    >
                      {(fawnies.maxClaim > 0) ? 
                        <>You are eligible for {fawnies.maxClaim} free Fawnies and have claimed {fawnies.claimed}.</> : 
                        <></>
                      }
                    </s.TextTitle>
                    <s.SpacerXSmall />
                    <s.TextTitle
                      style={{ textAlign: "center", color: "var(--fawnies-accent-text)" }}
                    >
                      {(fawnies.maxClaim > fawnies.claimed) ? 
                        <>Additional Fawnies cost 0.004E each.</> : 
                        <>Fawnies cost 0.004E each.</>
                      }
                    </s.TextTitle>
                    <s.SpacerXSmall />
                    <s.TextDescription
                      style={{ textAlign: "center", color: "var(--fawnies-accent-text)" }}
                    >
                      Excluding gas fees.
                    </s.TextDescription>
                    <s.SpacerLarge />
                    {fawnies.maxClaim > fawnies.claimed ? 
                      <>
                        <s.TextDescription style={{fontSize: "20px"}}>
                          Claim: {fawnies.maxClaim - fawnies.claimed} Free Fawnies
                        </s.TextDescription>
                        <s.SpacerXSmall />
                      </> :
                      <></>
                    }
                    <s.TextDescription style={{fontSize: "20px", color: "var(--fawnies-primary-text)"}}>
                      Purchase: <input type="text" style={{marginLeft: "5px", marginRight: "5px", fontSize: "20px", width: "100px", textAlign: "center"}} value={paidMintCount} onChange={(e) => { verifyPaidMintCount(e.target);}} /> Fawnies
                    </s.TextDescription>
                    {blockchain.account === "" ||
                    blockchain.fawniesContract === null ? (<></>
                    ) : (
                      <>
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--fawnies-accent-text)",
                          }}
                        >
                          {fawniesFeedback}
                        </s.TextDescription>
                        <s.SpacerMedium />
                        
                        <s.Container ai={"center"} jc={"center"} fd={"row"}>
                          <StyledBuyButton
                            disabled={claimingNft  ? 1 : 0}
                            onClick={(e) => {
                              e.preventDefault();
                              mintFawnies();
                              getFawnies();
                            }}
                          >
                            {claimingNft ? "BUSY" : "MINT"}
                          </StyledBuyButton>
                        </s.Container>
                        <s.SpacerSmall />
                        <s.TextDescription style={{fontSize: "15px", color: "var(--fawnies-primary-text)"}}>
                          Cost: {paidMintCount * fawnies.publicMintPrice / 10**18} ETH
                        </s.TextDescription>
                      </>
                    )}
                  </> }
                        </>,
                        'Stories': 
                        <>
                          <s.TextDescription style={{paddingTop: "30px", paddingBottom: "15px", width: "90%", textAlign: "center", color: "var(--stories-accent-text)", fontSize: "30px" }}>
                            This story begins with a Pixie that has Valentine's Day on her mind. She's thinking about the roses she has been growing in her garden and her plans for the big day. Follow along as she gets ready to stun the world!
                          </s.TextDescription>
                          <s.TextDescription style={{paddingTop: "15px", paddingBottom: "30px", width: "90%", textAlign: "center", color: "var(--stories-accent-text)", fontSize: "25px" }}>
                            Be mindful that to reach the end of the story you will need to keep at least one token from each day so plan your journey accordingly!
                          </s.TextDescription>
                          { stories.activeRecipes && stories.activeRecipes.length > 0 ?
                            <>
                              <s.Container ai={"center"} jc={"center"} fd={"column"} fw={"wrap"}>
                                { stories.activeRecipes.map((recipe,index) =>
                                  <>
                                    <s.Container ai={"center"} jc={"center"} fd={"row"} fw={"wrap"}>
                                      
                                      { recipe.burnTokenIds.length > 0 ?
                                        <>
                                            {
                                              recipe.burnTokenIds.map((burnTokenId,burnTokenIndex) =>
                                              <>
                                                <s.Container ai={"center"} jc={"center"} fd={"column"} fw={"wrap"}>
                                                  <StyledStoryImg src={"https://pixiejars.app/stories/tokens/" + burnTokenId + ".gif"} />
                                                  <s.SpacerSmall />
                                                  <s.TextDescription style={{ textAlign: "center", color: "var(--stories-accent-text)", fontSize: "20px" }}>
                                                    BURN: {storiesRecipeMultiplier[index] * recipe.burnTokenQuantities[burnTokenIndex]}
                                                  </s.TextDescription>
                                                  {blockchain.account !== "" ? 
                                                     <s.TextDescription style={{ textAlign: "center", color: "var(--stories-accent-text)", fontSize: "20px" }}>
                                                      BALANCE: {stories.tokenBalances[burnTokenId]}
                                                     </s.TextDescription> : null }
                                                </s.Container>
                                              </>)
                                            }
                                        </>
                                      : null }

                                      { recipe.mintTokenIds.length > 0 ?
                                        <>
                                            {
                                              recipe.mintTokenIds.map((mintTokenId,mintTokenIndex) =>
                                              <>
                                                <s.Container ai={"center"} jc={"center"} fd={"column"} fw={"wrap"}>
                                                  <StyledStoryImg src={"https://pixiejars.app/stories/tokens/" + mintTokenId + ".gif"} />
                                                  <s.SpacerSmall />
                                                  <s.TextDescription style={{ textAlign: "center", color: "var(--stories-accent-text)", fontSize: "20px" }}>MINT: {storiesRecipeMultiplier[index] * recipe.mintTokenQuantities[mintTokenIndex]}</s.TextDescription>
                                                  {blockchain.account !== "" ? 
                                                     <s.TextDescription style={{ textAlign: "center", color: "var(--stories-accent-text)", fontSize: "20px" }}>
                                                      BALANCE: {stories.tokenBalances[mintTokenId]}
                                                     </s.TextDescription> : null }
                                                </s.Container>
                                              </>)
                                            }
                                        </>
                                      : null }
                                    </s.Container>
                                    <s.SpacerMedium />
                                    <s.Container ai={"center"} jc={"center"} fd={"column"} fw={"wrap"}>
                                      <s.Container ai={"center"} jc={"center"} fd={"row"} fw={"wrap"}>
                                        <s.TextDescription style={{ color: "var(--stories-accent-text)", fontSize: "20px", }}>
                                                MULTIPLIER: 
                                              </s.TextDescription>
                                              <s.SpacerSmall />
                                        <input defaultValue={"5"} value={storiesRecipeMultiplier[index]} type="text" 
                                          style={{ width: "100px", height: "30px", fontSize: "25px", textAlign: "center", }} 
                                          onChange={(e) => { updateRecipeMultiplier(e, index); }} />
                                      </s.Container>
                                      { recipe.cost > 0 ? 
                                        <s.Container ai={"center"} jc={"center"} fd={"row"} fw={"wrap"}>
                                          <s.TextDescription style={{ color: "var(--stories-accent-text)", fontSize: "20px", }}>
                                            COST: {recipe.cost * storiesRecipeMultiplier[index] / 10**9}E
                                          </s.TextDescription>
                                        </s.Container>
                                      : null}
                                      { recipe.pixieDustCost > 0 ? 
                                        <s.Container ai={"center"} jc={"center"} fd={"row"} fw={"wrap"}>
                                          <s.TextDescription style={{ color: "var(--stories-accent-text)", fontSize: "20px", }}>
                                            PIXIE DUST: {recipe.pixieDustCost * storiesRecipeMultiplier[index]} / {Math.floor(dust.balance/10**16)/10**2}
                                          </s.TextDescription>
                                        </s.Container>
                                      : null}
                                      <s.Container ai={"center"} jc={"center"} fd={"row"} fw={"wrap"}>
                                        <s.TextDescription style={{ color: "var(--stories-accent-text)", fontSize: "20px", }}>
                                          ENDS: {(new Date(recipe.endTime * 1000)).toLocaleString()}
                                        </s.TextDescription>
                                      </s.Container>
                                      { ((blockchain.account === "" ||
                                          blockchain.storiesContract === null)) ? 
                                        <>
                                          <s.Container ai={"center"} jc={"center"}>
                                            <StyledButton style={{backgroundColor: 'var(--nav-button-stories)'}} onClick={(e) => { e.preventDefault(); dispatch(connect()); }}>
                                              CONNECT
                                            </StyledButton>
                                            {blockchain.errorMsg !== "" ? (
                                              <>
                                                <s.SpacerSmall />
                                                <s.TextDescription style={{ textAlign: "center", color: "var(--stories-accent-text)", }}>{blockchain.errorMsg}</s.TextDescription>
                                              </>) : null}
                                          </s.Container>
                                        </> : 
                                        <>
                                                { validBurnQuantities(recipe, index) ? 
                                                  <>
                                                    {
                                                      validPixieDust(recipe, index) ?
                                                      <>
                                                            <s.SpacerMedium />
                                                            <s.Container ai={"center"} jc={"center"} fd={"row"}>
                                                              <StoriesMintButton disabled={claimingNft  ? 1 : 0} onClick={(e) => { e.preventDefault(); mintStory(recipe,index); getStories(); }} >
                                                                {claimingNft ? "BUSY" : "MINT"}
                                                              </StoriesMintButton>
                                                            </s.Container>
                                                            <s.SpacerMedium />
                                                            <s.TextDescription style={{ fontSize: "20px", textAlign: "center", color: "var(--stories-accent-text)", }}>
                                                              {storiesFeedback[index]}
                                                            </s.TextDescription>
                                                      </> :
                                                      <>
                                                        <s.TextDescription style={{ color: "var(--stories-accent-text)", fontSize: "20px", }}>
                                                          NOT ENOUGH PIXIE DUST TO MINT
                                                        </s.TextDescription>
                                                      </>
                                                    }
                                                  </> : 
                                                  <>
                                                    <s.TextDescription style={{ color: "var(--stories-accent-text)", fontSize: "20px", }}>
                                                      INSUFFICIENT BURN TOKEN BALANCES
                                                    </s.TextDescription>
                                                  </>
                                                }
                                          </>
                                        }
                                    </s.Container>
                                    <s.SpacerLarge />
                                  </>
                                )}
                              </s.Container>
                            </> :
                            <>
                              <s.TextTitle style={{ textAlign: "center", color: "var(--stories-accent-text)" }} >
                                No active mints.
                              </s.TextTitle>
                            </>
                          }
                        </>
                    }[currentPage]
                  }
                  </>
                )}
            <s.SpacerMedium />
          </s.Container>
          <s.SpacerLarge />
        </ResponsiveWrapper>
        <s.SpacerMedium />
      </s.Container>
    </s.Screen>
  );
}

export default App;
