// log
import store from "../store";
import Web3 from "web3";
const { MerkleTree } = require('merkletreejs')


const fetchCompanionsRequest = () => {
  return {
    type: "CHECK_COMPANIONS_REQUEST",
  };
};

const fetchCompanionsSuccess = (payload) => {
  return {
    type: "CHECK_COMPANIONS_SUCCESS",
    payload: payload,
  };
};

const fetchCheckApprovalsSuccess = (payload) => {
  return {
    type: "CHECK_CHECK_APPROVALS_SUCCESS",
    payload: payload,
  };
};

const fetchCompanionsFailed = (payload) => {
  return {
    type: "CHECK_COMPANIONS_FAILED",
    payload: payload,
  };
};

export const fetchCompanions = (blockchainAccount) => {
  return async (dispatch) => {
    dispatch(fetchCompanionsRequest());

    try {
      const configResponse = await fetch("/config/configv2.json", {cache: "no-store"}, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      const CONFIG = await configResponse.json();
      

      let pixieJars = await store.getState().blockchain.pixiejarsContract.methods.walletOfOwner(blockchainAccount).call();
      let companionJarIds = await store.getState().blockchain.walletOfOwnerContract.methods.walletOfOwner(CONFIG.COMPANION_JARS_CONTRACT_ADDRESS, blockchainAccount, 1, 5000).call();
      let pandies = await store.getState().blockchain.walletOfOwnerContract.methods.walletOfOwner(CONFIG.PANDIES_CONTRACT_ADDRESS, blockchainAccount, 1, 10000).call();
      let fawnies = await store.getState().blockchain.walletOfOwnerContract.methods.walletOfOwner(CONFIG.FAWNIES_CONTRACT_ADDRESS, blockchainAccount, 1, 5000).call();
      let pixiesApproved = await store.getState().blockchain.pixiejarsContract.methods.isApprovedForAll(blockchainAccount, CONFIG.COMPANION_JARS_CONTRACT_ADDRESS).call();
      let pandiesApproved = await store.getState().blockchain.pandiesContract.methods.isApprovedForAll(blockchainAccount, CONFIG.COMPANION_JARS_CONTRACT_ADDRESS).call();
      let fawniesApproved = await store.getState().blockchain.fawniesContract.methods.isApprovedForAll(blockchainAccount, CONFIG.COMPANION_JARS_CONTRACT_ADDRESS).call();

      let companionJars = [];
      for(let i = 0;i < companionJarIds.length;i++) {
        let pixieTokenId = companionJarIds[i];
        let companionInfo = await store.getState().blockchain.companionjarsContract.methods.companionInfo(pixieTokenId).call();
        let companionTokenId = companionInfo["companionTokenId"];
        let companionCollection = companionInfo["companionCollection"];
        let companionData = {"pixieTokenId": pixieTokenId, "companionCollection": companionCollection, "companionTokenId": companionTokenId};
        companionJars.push(companionData);
      }

      dispatch(
        fetchCompanionsSuccess({
          pixieJars,
          companionJars,
          pandies,
          fawnies,
          pixiesApproved,
          pandiesApproved,
          fawniesApproved,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchCompanionsFailed("Could not load data from contract."));
    }
  };
};

export const fetchCheckApprovals = (blockchainAccount) => {
  return async (dispatch) => {
    dispatch(fetchCompanionsRequest());

    try {
      const configResponse = await fetch("/config/configv2.json", {cache: "no-store"}, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      const CONFIG = await configResponse.json();

      let pixiesApproved = await store.getState().blockchain.pixiejarsContract.methods.isApprovedForAll(blockchainAccount, CONFIG.COMPANION_JARS_CONTRACT_ADDRESS).call();
      let pandiesApproved = await store.getState().blockchain.pandiesContract.methods.isApprovedForAll(blockchainAccount, CONFIG.COMPANION_JARS_CONTRACT_ADDRESS).call();
      let fawniesApproved = await store.getState().blockchain.fawniesContract.methods.isApprovedForAll(blockchainAccount, CONFIG.COMPANION_JARS_CONTRACT_ADDRESS).call();

      dispatch(
        fetchCheckApprovalsSuccess({
          pixiesApproved,
          pandiesApproved,
          fawniesApproved,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchCompanionsFailed("Could not load data from contract."));
    }
  };
};
