// log
import store from "../store";
import Web3 from "web3";
const { MerkleTree } = require('merkletreejs')


const fetchDustRequest = () => {
  return {
    type: "CHECK_DUST_REQUEST",
  };
};

const fetchDustSuccess = (payload) => {
  return {
    type: "CHECK_DUST_SUCCESS",
    payload: payload,
  };
};

const fetchPixieClaimedSuccess = (payload) => {
  return {
    type: "CHECK_PIXIE_CLAIMED_SUCCESS",
    payload: payload,
  };
};

const fetchDustBalanceSuccess = (payload) => {
  return {
    type: "CHECK_DUST_BALANCE_SUCCESS",
    payload: payload,
  };
};

const fetchDustFailed = (payload) => {
  return {
    type: "CHECK_DUST_FAILED",
    payload: payload,
  };
};

export const fetchDust = (blockchainAccount) => {
  return async (dispatch) => {
    dispatch(fetchDustRequest());

    try {
      let balance = await store.getState().blockchain.pixiedustContract.methods.balanceOf(blockchainAccount).call();
      let unclaimedStakingDust = await store.getState().blockchain.companionjarsContract.methods.unclaimedDust(blockchainAccount).call();
      let unclaimedPixies = [];
      try {
        unclaimedPixies = await store.getState().blockchain.pixiedustContract.methods.unclaimedPixiesByOwner(blockchainAccount).call();
      } catch (err) {}

      dispatch(
        fetchDustSuccess({
          balance,
          unclaimedStakingDust,
          unclaimedPixies
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDustFailed("Could not load data from contract."));
    }
  };
};

export const fetchDustBalance = (blockchainAccount) => {
  return async (dispatch) => {
    dispatch(fetchDustRequest());

    try {
      let balance = await store.getState().blockchain.pixiedustContract.methods.balanceOf(blockchainAccount).call();

      dispatch(
        fetchDustBalanceSuccess({
          balance,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDustFailed("Could not load data from contract."));
    }
  };
};

export const fetchCheckPixieClaimed = (pixieId) => {
  return async (dispatch) => {
    dispatch(fetchDustRequest());

    try {

      let pixieIsClaimed = await store.getState().blockchain.pixiedustContract.methods.isClaimed(pixieId).call();

      dispatch(
        fetchPixieClaimedSuccess({
          pixieIsClaimed
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDustFailed("Could not load data from contract."));
    }
  };
};
